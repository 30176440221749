import { React, useState } from "react";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import "./viewStyles/excelView.css";
import SubHeader from "./subheader";
import PdfViewer from "./pdfViewer";

function ExcelView() {
  return (
    <>
      <Header />
      <SubHeader title={"Activities"} />
      <div className="data-table container justify-content-start">
        <PdfViewer />
      </div>
      <Footer />
    </>
  );
}

export default ExcelView;
