import React, { useEffect, useState } from "react";
import "./viewStyles/admin.css";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import SubHeader from "./subheader";
import { BsGlobeAmericas } from "react-icons/bs";
// import { FcExport } from "react-icons/fc";
import { BiExport } from "react-icons/bi";
import {
  banyanFilterMemberDetailsApi,
  getAdminDetails,
  paginateAdminDetails,
} from "../serviceApi/Auth-Services";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import SpinnerWrapper from "./spinner";
import Pagination from "react-js-pagination";

const Admin = () => {
  const [adminDetails, setAdminDetails] = useState([]);
  const [isError, setIsError] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Items per page
  const [getAllData, setGetAllData] = useState([]);
  const [showAllData, setShowAllData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const initialFormState = {
    name: "",
    email: "",
    mobileNumber: "",
    gender: "",
    communicationMode: "",
    membershipType: "",
    address: "",
    occupation: "",
    course: "",
    branch: "",
    yearOfPassing: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    setIsLoading(true);
    // loginCheck();
    // loadData();
    displayAllDetails();
  }, []);

  const loginCheck = () => {
    // let userName = localStorage.getItem("userDetails");
    // if (userName === "" || userName === null)
    // {
    //     navigate("/");
    // }
  };

  const loadData = () => {
    const payload = {
      pageNumber: 1,
    };
    paginateAdminDetails(payload)
      .then((responseData) => {
        setIsLoading(false);
        const fetchAdminDetails = responseData;
        setAdminDetails(fetchAdminDetails);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setIsError(error.message);
      });
  };

  // const handleNextClick = () => {
  //     console.log(currentPage);
  //     setCurrentPage(currentPage + 1);
  //     loadPaginateData(currentPage + 1);
  // };

  // const handlePreviousClick = () => {
  //     console.log(currentPage);
  //     if (currentPage > 1) {
  //         setCurrentPage(currentPage - 1);
  //         loadPaginateData(currentPage - 1)
  //     }
  // };

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   let currentPage = pageNumber;
  //   console.log(currentPage);
  //   loadPaginateData(currentPage);
  // };

  const loadPaginateData = (page) => {
    setIsLoading(true);
    const payload = {
      pageNumber: page,
    };
    paginateAdminDetails(payload)
      .then((responseData) => {
        setIsLoading(false);
        const fetchAdminDetails = responseData;
        setAdminDetails(fetchAdminDetails);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setIsError(error.message);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const searchFilterHandler = (event) => {
  //   event.preventDefault();
  //   toast.dismiss();
  //   setIsLoading(true);
  //   const filteredAdminData = Object.fromEntries(
  //     Object.entries(formData).filter(([_, value]) => value !== "")
  //   );
  //   let payloadData = {};
  //   for (const key in filteredAdminData) {
  //     let payload = {
  //       field: key,
  //       value: filteredAdminData[key],
  //     };
  //     payloadData = payload;
  //     console.log(payloadData);
  //   }
  //   banyanFilterMemberDetailsApi(payloadData)
  //     .then((response) => {
  //       if (response.status === "success") {
  //         // alert("Filtered data success")
  //         setIsLoading(false);
  //         toast.success(response.message);
  //         console.log(response.requestedUserDetils);
  //         setFilteredData(response.requestedUserDetils);
  //         setGetAllData(false);
  //       } else {
  //         delete response.requestedUserDetils;
  //         toast.error(response.message);
  //         setFilteredData(false);
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("A network error occurred");
  //       setIsLoading(false);
  //       setIsError(err.message);
  //     });
  //   // }
  // };

  const searchFilterOpen = () => {
    setOpenFilter(!openFilter);
  };

  const onExportExcelAdmin = () => {
    const exportData = adminDetails.map((item, index) => ({
      Id: index + 1,
      Name: item.name,
      Email: item.email,
      Mobile: item.mobileNumber.toString(),
      Gender: item.gender,
      CommunicationMode: item.communicationMode,
      MembershipType: item.membershipType,
      Address: item.address,
      Occupation: item.occupation,
      Course: item.course,
      Branch: item.branch,
      YearOfPassing: item.yearOfPassing,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AdminDetails");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(blob, "admin_details.xlsx");
  };

  // const onExportExcelAdmin = () => {
  //   console.log("adminExcel");
  //   const filteredExcelData = adminDetails.map((data, index) => ({
  //     Id: index + 1,
  //     Name: data.name,
  //     Email: data.email,
  //     Mobile: data.mobileNumber.toString(),
  //     Gender: data.gender,
  //     CommunicationMode: data.communicationMode,
  //     MembershipType: data.membershipType,
  //     Address: data.address,
  //     Occupation: data.occupation,
  //     Course: data.course,
  //     Branch: data.branch,
  //     YearOfPassing: data.yearOfPassing,
  //   }));
  //   const ws = XLSX.utils.json_to_sheet(filteredExcelData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, "Excel Export" + fileExtension);
  // };

  // const onExportExcelFiltered = () => {
  //   console.log("filterExcel");
  //   const defaultFilterExcel = [];
  //   const filterExcel = {
  //     Id: filteredData.userID,
  //     Name: filteredData.name,
  //     Email: filteredData.email,
  //     Mobile: filteredData.mobileNumber.toString(),
  //     Gender: filteredData.gender,
  //     CommunicationMode: filteredData.communicationMode,
  //     MembershipType: filteredData.membershipType,
  //     Address: filteredData.address,
  //     Occupation: filteredData.occupation,
  //     Course: filteredData.course,
  //     Branch: filteredData.branch,
  //     YearOfPassing: filteredData.yearOfPassing,
  //   };
  //   defaultFilterExcel.push(filterExcel);
  //   console.log(defaultFilterExcel);
  //   const ws = XLSX.utils.json_to_sheet(defaultFilterExcel);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, "Excel Export" + fileExtension);
  // };

  // const onExportExcelAllData = () => {
  //   console.log("AlldataExcel");
  //   const filteredExcelData = getAllData.map((data) => ({
  //     Id: data.userID,
  //     Name: data.name,
  //     Email: data.email,
  //     Mobile: data.mobileNumber.toString(),
  //     Gender: data.gender,
  //     CommunicationMode: data.communicationMode,
  //     MembershipType: data.membershipType,
  //     Address: data.address,
  //     Occupation: data.occupation,
  //     Course: data.course,
  //     Branch: data.branch,
  //     YearOfPassing: data.yearOfPassing,
  //   }));
  //   const ws = XLSX.utils.json_to_sheet(filteredExcelData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, "Excel Export" + fileExtension);
  // };

  // const displayAllDetails = () => {
  //   setIsLoading(true);
  //   console.log("Display All");
  //   getAdminDetails()
  //     .then((responseData) => {
  //       setIsLoading(false);
  //       const fetchAdminDetails = responseData;
  //       console.log(fetchAdminDetails);
  //       setGetAllData(fetchAdminDetails);
  //       // setShowAllData(!showAllData);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error);
  //       setIsError(error.message);
  //     });
  // };
  const displayAllDetails = () => {
    setIsLoading(true);
    getAdminDetails()
      .then((responseData) => {
        setIsLoading(false);
        const fetchAdminDetails = responseData;
        setAdminDetails(fetchAdminDetails);
        setFilteredData(fetchAdminDetails); // Initialize filteredData with all admin details
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setIsError(error.message);
      });
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = adminDetails.filter(
      (detail) =>
        detail.userID?.toLowerCase().includes(query.toLowerCase()) ||
        detail.name?.toLowerCase().includes(query.toLowerCase()) ||
        detail.username?.toLowerCase().includes(query.toLowerCase()) ||
        detail.email?.toLowerCase().includes(query.toLowerCase()) ||
        detail.gender?.toLowerCase().includes(query.toLowerCase()) ||
        detail.mobileNumber?.toLowerCase().includes(query.toLowerCase()) ||
        detail.communicationMode?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when searching
  };
  // const handleSearch = (event) => {
  //   const query = event.target.value.toLowerCase().trim(); // Trim whitespace and convert to lowercase

  //   const filtered = adminDetails.filter((detail) => {
  //     // Check if any field matches the query
  //     return (
  //       (detail.userID && detail.userID.toLowerCase().includes(query)) ||
  //       (detail.name && detail.name.toLowerCase().includes(query)) ||
  //       (detail.username && detail.username.toLowerCase().includes(query)) ||
  //       (detail.email && detail.email.toLowerCase().includes(query)) ||
  //       (detail.gender && detail.gender.toLowerCase() === query) || // Exact match for gender
  //       (detail.mobileNumber && detail.mobileNumber.toLowerCase().includes(query)) ||
  //       (detail.communicationMode && detail.communicationMode.toLowerCase().includes(query))
  //     );
  //   });

  //   setFilteredData(filtered);
  //   setCurrentPage(1); // Reset to first page when searching
  // };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate number of pages
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  console.log(totalPages);

  const totalItems = filteredData?.length || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Members Area" />
      {isLoading && <SpinnerWrapper />}
      <div
        className="data-table container-fluid rounded"
        style={{ border: "2px solid grey", width: "98%" }}
      >
        <div className="row">
          <nav className="navbar navbar-light table-home p-0">
            <div className="container-fluid main_table">
              <h4 className="navbar-brand mt-2 home_title">
                <BsGlobeAmericas color="#f48739" /> HOME
              </h4>
              <div className="d-flex">
                <h6 className="navbar-brand mt-2"></h6>
              </div>
            </div>
          </nav>
          <nav
            className="navbar inputs-panel"
            style={{ backgroundColor: "black" }}
          >
            <div className="container-fluid input_table">
              <div className="navbar-brand">
                {/* <button
                  className="btn btn-primary search_btns"
                  onClick={searchFilterOpen}
                >
                  {openFilter ? "Cancel" : "Search"}
                </button> */}
                <form className="search-filter-form">
                  <div>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearch}
                      className="form-control custom-shadow"
                    />
                  </div>
                </form>
                {/* <h4 className="navbar-brand home_title">Search</h4> */}
              </div>

              <div className="d-flex">
                <div className="dropdown mx-2">
                  <button
                    className="btn btn-primary btn-sm dropdown-toggle m-0 search_btns"
                    type="submit"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BiExport size={25} />
                  </button>
                  <ul
                    className="dropdown-menu menu_box pb-0"
                    style={{ left: "-150%" }}
                  >
                    <h6 className="mx-3 p-0 menu_info">Export Page Data</h6>
                    <li onClick={onExportExcelAdmin}>
                      <a className="dropdown-item menu_item">Excel</a>
                    </li>
                  </ul>
                </div>
                {/* <button className="btn btn-primary btn-sm m-0 search_btns" type="submit" onClick={displayAllDetails}>
                                    <BiExport size={25} /> All
                                </button> */}
              </div>
            </div>
          </nav>
          {/* {openFilter && (
            <>
              <hr></hr>
              <nav className="navbar" id="filter-panel">
                <div className="container-fluid">
                  <form
                    className="search-filter-form mt-4"
                    onSubmit={searchFilterHandler}
                  >
                    <h5
                      className="text-center"
                      style={{ color: "#337ab7", fontSize: "20px" }}
                    >
                      Filter Details
                    </h5>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12  mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Number"
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12  mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Gender"
                          name="gender"
                          value={formData.gender}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12  mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Communication Mode"
                          name="communicationMode"
                          value={formData.communicationMode}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Membership"
                          name="membershipType"
                          value={formData.membershipType}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Occupation"
                          name="occupation"
                          value={formData.occupation}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Year of passing"
                          name="yearOfPassing"
                          value={formData.yearOfPassing}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Course"
                          name="course"
                          value={formData.course}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <input
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Branch"
                          name="branch"
                          value={formData.branch}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <textarea
                          className="form-control custom-shadow"
                          type="text"
                          placeholder="Address"
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="filter-form mx-2 m-4 text-center">
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </nav>
            </>
          )} */}
          <div className="container-fluid p-0 tables_list">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="tab-naming">Id</th>
                    {/* <th>
                                            <input type="checkbox" />
                                        </th> */}
                    <th className="col-2 tab-naming">Name</th>
                    <th className="tab-naming">Username</th>
                    <th className="col-2 tab-naming">Mobile</th>
                    <th className="tab-naming">Gender</th>
                    <th className="col-2 tab-naming">Com Mode</th>
                    <th className="col-1 tab-naming">Membership</th>
                    <th className="col-1 tab-naming">Address</th>
                    <th className="tab-naming">Occupation</th>
                    <th className="tab-naming">Course</th>
                    <th className="tab-naming">Branch</th>
                    <th className="col-2 tab-naming">Year Of Passing</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((data, index) => (
                    <tr className="col-2" key={index}>
                      <td className="table-details">{data.userID}</td>
                      <td className="table-details">{data.name}</td>
                      <td className="table-details">{data.email}</td>
                      <td className="table-details">{data.mobileNumber}</td>
                      <td className="table-details">{data.gender}</td>
                      <td className="table-details">
                        {data.communicationMode}
                      </td>
                      <td className="table-details">{data.membershipType}</td>
                      <td className="table-details">{data.address}</td>
                      <td className="table-details">{data.occupation}</td>
                      <td className="table-details">{data.course}</td>
                      <td className="table-details">{data.branch}</td>
                      <td className="table-details">{data.yearOfPassing}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
              {currentItems?.length === 0 && (
                <h5 className="text-center text-danger">No results found</h5>
              )}
              {
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                  <div className="mx-2 d-flex justify-content-center align-items-center">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={filteredData?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                  <h6 className="text-white mx-2">
                    Showing {startIndex} to {endIndex} of {totalItems} entries
                  </h6>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Admin;
