import React from "react";
import "./viewStyles/subheader.css";

const SubHeader = ({ title }) => {
  return (
    <div className="breadcrumbs-subheader">
      {/* <main className="container">
        <h2 className="subheader-title">{title}</h2>
      </main> */}
    </div>
  );
};

export default SubHeader;
