import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
      <div className="col-md-10">
        <div className="col-md-6 offset-md-3 text-center text-white">
          <h1 className="display-4">404 Not Found</h1>
          <p className="lead">
            Sorry, the page you're looking for doesn't exist.
          </p>
          <Link onClick={() => window.history.back()}>
            <button className="btn btn-block btn-success">Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
