import React from "react";
import Logo from "../assets/images/role-of-education.webp";
import Logo2 from "../assets/images/graphic1.jpg";
import Logo3 from "../assets/images/welfare.jpg";
import Logo4 from "../assets/images/environment.jpg";
import Logo5 from "../assets/images/nature_garden.jpg";
import Logo6 from "../assets/images/leader.webp";
import "./viewStyles/objective.css";
import { BsCheckCircleFill } from "react-icons/bs";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import SubHeader from "./subheader";

const Objective = () => {
  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Objective" />
      <section id="Education" className="gallery">
        <div className="container">
          <h2 className="f-primary-b mar-bot-50 text-center objective_title">
            Education
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <img
                src={Logo}
                className="img-responsive img-thumbnail"
                style={{ height: "100%", width: "100%" }}
                alt="education"
              />
            </div>
            <div className="col-md-6 pad-lef-50">
              <p className="events-info">
                <BsCheckCircleFill color="green" />{" "}
                To offer educational allowance every semester for deserving PEC
                students.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" />{" "}
                To sponsor examination fees for poor and meritorious PEC
                students
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To conduct “Career awareness
                programme” for PEC students.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To provide internships to
                PEC students during vacation.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To guide students to prepare
                for Civil Services examination.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To conduct technical
                competition/quiz organized/conducted by alumni.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To offer Career guidance for
                all courses under CENTAC administration.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To aid in educating
                deserving students from government schools and orphanages.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="Women" className="stats-counter">
        <div className="container">
          <h2 className="women-empower mar-bot-50 text-center objective_title">
            Women Empowerment
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <p className="women-empower">
                <BsCheckCircleFill color="green" /> To aid women to come out of
                the closed world and realize their esteem.
              </p>
              <p className="women-empower">
                <BsCheckCircleFill color="green" /> To create awareness that
                transformation in the world is possible only with the help of
                women.
              </p>
              <p className="women-empower">
                <BsCheckCircleFill color="green" /> To create an awareness about
                the importance of mental and physical strength of the women.
              </p>
              <p className="women-empower">
                <BsCheckCircleFill color="green" /> A healthy women are the
                driving force for a healthy world.
              </p>
            </div>
            <div className="col-md-5 pad-lef-50">
              <img
                src={Logo2}
                className="img-responsive img-thumbnail"
                style={{ height: "90%", width: "100%" }}
                alt="nature-gardening"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section id="Welfare" className="stats-counter3"> */}
      <section id="Welfare" className="gallery">
        <div className="container">
          <h2 className="f-primary-b mar-bot-50 text-center objective_title">
            Welfare
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <img
                src={Logo3}
                className="img-responsive img-thumbnail"
                style={{ height: "100%", width: "100%" }}
                alt="education"
              />
            </div>
            <div className="col-md-6 pad-lef-50">
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To offer Lunch token and Bus
                fare for deserving PEC students identified by E.S Sir.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To offer assistance in
                filling online application for CENTAC admission.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To offer assistance for
                blind school opposite to PEC campus.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To celebrate monthly
                birthday at an orphanage.{" "}
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> Healthy Living – Yoga for
                Youth Empowerment.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="stats-counter2" id="Environment">
        <div className="container">
          <h2 className="environment mar-bot-50 text-center objective_title">
            Environment
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <p className="environment">
                <BsCheckCircleFill color="green" /> To help keep the PEC campus
                clean & Green.
              </p>
              <p className="environment">
                <BsCheckCircleFill color="green" /> To offer help in creating an
                Recycle plant inside PEC.
              </p>
              <p className="environment">
                <BsCheckCircleFill color="green" /> To determine the quality of
                drinking water in PEC and recommend treatment to college
                management.
              </p>
            </div>
            <div className="col-md-5 pad-lef-50">
              <img
                src={Logo4}
                className="img-responsive img-thumbnail"
                style={{ height: "100%", width: "100%" }}
                alt="nature-gardening"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="Nature" className="gallery">
        {/* <section id="Nature" className="stats-counter3"> */}
        <div className="container">
          <h2 className="f-primary-b mar-bot-50 text-center objective_title">
            Nature Gardening
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <img
                src={Logo5}
                className="img-responsive img-thumbnail"
                style={{ height: "100%", width: "100%" }}
                alt="education"
              />
            </div>
            <div className="col-md-6 pad-lef-50">
              <p className="events-info">
                <BsCheckCircleFill color="green" /> Setting up an organic farm
                and promote growing GMO free vegetable and create awareness
                among public.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> Distributing fruit tree
                saplings and long term trees to PEC students and staffs on
                special occasions.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> Planting tree saplings
                inside PEC to promote green environment.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> Also by Distributing fruit
                giving tree saplings to women self help group to promote green
                environment also helps them to provide some financial aid.
              </p>
              <p className="events-info">
                <BsCheckCircleFill color="green" /> To promote green environment
                and to protect our mother earth.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="Youth" className="gallery">
        <div className="container">
          <h2 className="f-primary-b mar-bot-50 text-center objective_title">
            Youth Leadership / Young Women Leadership
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <p className="events-info">
                In an effort to involve our kids and extend “Le Banyan” beyond
                our borders, “Youth Leadership / Young Women Leadership” is
                being launched. High schools from the US can pick up project
                that Le Banyan identifies, campaigns for, and executes them in
                person, similar to the “exchange program.” This program will
                enhance leadership experience, which will help them in their
                undergraduate placement.
              </p>
            </div>
            <div className="col-md-5 pad-lef-50">
              <img
                src={Logo6}
                className="img-responsive img-thumbnail"
                style={{ height: "100%", width: "100%" }}
                alt="nature-gardening"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Objective;
