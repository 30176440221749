import axios from "axios";
import { BANAYAN_URL } from "./baseUrl";
import { Navigate } from "react-router-dom";

let config = {
    headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://localhost:3000",
        // 'Access-Control-Allow-Credentials': "true"
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods":
        // "GET,HEAD,PUT,OPTIONS,PATCH,POST,DELETE,OPTIONS",
    },
};
let config2 = {
    headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://localhost:3000",
        // 'Access-Control-Allow-Credentials': "true"
        // "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
        "GET,HEAD,PUT,OPTIONS,PATCH,POST,DELETE,OPTIONS",
    },
};


export const banyanLoginApi = async (payload) => {
    console.log("userLogin_api payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/signin`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userLogin_api error", error);
        throw error;
    }
};

export const banyanRegisterApi = async (payload) => {
    console.log("userRegister_api payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/signUp`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userRegister_api error", error);
        throw error;
    }
};

export const banyanForgotPasswordApi = async (payload) => {
    console.log("userForgotPassword_api payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/forgotPassword`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userForgotPassword_api", error);
        throw error;
    }
};

export const banyanUpdatePasswordApi = async (payload) => {
    console.log("userUpdatePassword_api payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/updatePassword`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userUpdatePassword_api", error);
        throw error;
    }
};

export const banyanOtpVerificationApi = async (payload) => {
    console.log("userOtpVerification_api payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/OTP_verification`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userOtpVerification_api", error);
        throw error;
    }
};

export const banyanResendOtpApi = async (payload) => {
    console.log("userResendOtp_api payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/resend_OTP`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userResendOtpApi", error);
        throw error;
    }
};

export const banyanContactUsApi = async (payload) => {
    console.log("userContactUsApi payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/contactUs`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userContactUsApi error", error);
        throw error;
    }
};

export const banyanFilterMemberDetailsApi = async (payload) => {
    console.log("userFilterDetailsApi payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BANAYAN_URL}/auth/retrieveRequestedData`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("userFilterDetailsApi error", error);
        throw error;
    }
};

export const getAdminDetails = async () => {
    try {
        const response = await axios.get(`${BANAYAN_URL}/auth/retrieve`);
        return response.data;
    } catch (error) {
        console.log("userFetchAllAdmin error", error)
        throw error;
    }
};
export const paginateAdminDetails = async (payload) => {
    try {
        const response = await axios.post(`${BANAYAN_URL}/auth/retrieveRequestedPageData`,
            payload,
            config
        );
        return response.data;
    } catch (error) {
        console.log("userpaginateAdmin error", error)
        throw error;
    }
};

export const scholarshipFormPost = async (payload) => {
    console.log(Object.fromEntries(payload),'payload')
    try {
        const response = await axios.post(`${BANAYAN_URL}/personalInfo/addPersonalInfo`,
            payload,
            config2
        );
        return response.data;
    } catch (error) {
        console.log("scholarshipFormerror", error)
        throw error;
    }
};

// export const Logout = (navigate) => {
//     localStorage.removeItem('userDetails');
//     navigate('/');
// }

export const Logout = () => {
    localStorage.removeItem('userDetails');
    window.location.href = '/';
}

const loginAuthenticated = () => {
    const banyanAdminUser = "65788b0d85f4f71aafe8ad14";
    let userId = localStorage.getItem('userDetails')?.includes(banyanAdminUser) ? true : false;
    return userId !== false;
};

export const ProtectedRoutes = ({ element }) => {
    if (loginAuthenticated()) {
        return element;
    } else {
        return <Navigate to="/" />;
    }
}


