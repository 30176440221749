import React, { useEffect, useState } from "react";
// import logo from "../assets/ImageCarousel/SLIDE6.jpg";
// import logo2 from "../assets/ImageCarousel/SLIDE7.jpg";
// import logo3 from "../assets/ImageCarousel/SLIDE2.jpg";
// import logo4 from "../assets/ImageCarousel/SLIDE1.jpg";
// import logo5 from "../assets/ImageCarousel/SLIDE3.jpg";
// import logo6 from "../assets/ImageCarousel/SLIDE4.jpg";
// import logo7 from "../assets/ImageCarousel/SLIDE5.jpg";
import sliderOne from "../assets/ImageCarousel/slider13.jpg";
import sliderTwo from "../assets/ImageCarousel/slider12.jpg";
import sliderThree from "../assets/ImageCarousel/slider14.jpg";
import sliderFour from "../assets/ImageCarousel/slider10cropped.jpeg";
import sliderFive from "../assets/ImageCarousel/slider9cropped.jpeg";
import sliderSix from "../assets/ImageCarousel/slider11cropped.jpeg";
import logo8 from "../assets/images/objective-img/women-empowerment.jpg";
import logo9 from "../assets/images/career.png";
import logo10 from "../assets/images/food.png";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import "./viewStyles/home.css";
import SpinnerWrapper from "./spinner";
import upcomingEvents from "../assets/images/upcomingEventsImg.jpg";
import drugPrevention from "../assets/images/upcoming-events1.jpg";
import sexualHarrasment from "../assets/images/upcoming-events2.webp";
import womenRights from "../assets/images/upcoming-events3.jpg";
import Testimonial from "./testimonies";
import PopupModal from "./popupModal";

const MainHome = () => {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //     setTimeout(() => {
  //         setIsLoading(false);
  //     }, 2000);
  // }, []);
  // const [showPopup, setShowPopup] = useState(true);

  // const togglePopup = () => {
  //   setShowPopup(!showPopup);
  //   console.log("close")
  // };
  // const getUserDetails = localStorage.getItem("userDetails");
  const [showPopup, setShowPopup] = useState(false); // Initialize to false

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");

    // Check if userDetails exists and popup hasn't been shown before
    if (userDetails && !localStorage.getItem("popupShown")) {
      setShowPopup(true);
      localStorage.setItem("popupShown", "true"); // Set the flag in localStorage
    }
  }, []); // Empty dependency array ensures this effect runs only once after mount

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <React.Fragment>
      <Header />
      {/* Image Carousel */}
      {/* {isLoading && <SpinnerWrapper />} */}
      <div className="breadcrumbs">
        <div
          id="carouselExampleIndicators"
          className="carousel slide carousel-fade mt-3"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            {/* <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button> */}
          </div>
          <div className="carousel-inner" style={{ padding: "0px" }}>
            <div className="carousel-item active">
              <img src={sliderTwo} className="d-block w-100" alt="slider-2" />
            </div>
            <div className="carousel-item">
              <img src={sliderThree} className="d-block w-100" alt="slider-3" />
            </div>
            <div className="carousel-item">
              <img src={sliderFour} className="d-block w-100" alt="slider-4" />
            </div>
            <div className="carousel-item">
              <img src={sliderFive} className="d-block w-100" alt="slider-5" />
            </div>
            <div className="carousel-item">
              <img src={sliderSix} className="d-block w-100" alt="slider-6" />
            </div>
            <div className="carousel-item">
              <img src={sliderOne} className="d-block w-100" alt="slider-1" />
            </div>
            {/* <div className="carousel-item">
              <img src={logo7} className="d-block w-100" alt="slider-7" />
            </div> */}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <section className="about-1-section wrapper">
        <div
          id="carouselExampleIndicators1"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          {/* <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators1"
              style={{ backgroundColor: "white" }}
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators1"
              style={{ backgroundColor: "white" }}
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators1"
              style={{ backgroundColor: "white" }}
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div> */}
          <div className="carousel-inner">
            {/* <div className="carousel-item active">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Upcoming</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-3">
                    <img
                      src={drugPrevention}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-8 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Drug prevention & Awareness
                    </h4>
                    <div className="dates-events">
                      <p className="el">
                        - By Mrs. Jayabharathy, IRS [2010] (our alumni) & Mrs.
                        Nalina Sofia, IRS [2010]
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Upcoming</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-3">
                    <img
                      src={womenRights}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-8 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Sexual Harassment in Workplace
                    </h4>
                    <div className="dates-events">
                      <p className="el">
                        – By HR leader from MNC [Ms Punitha Antony] – Logistics
                        being worked on.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Upcoming</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-3">
                    <img
                      src={sexualHarrasment}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-8 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Laws & Legislative Rights related to Women Safety
                    </h4>
                    <div className="dates-events">
                      <p className="el">
                        - Lawyer Mrs. Mrs Vrindha Mohan (specialized in women
                        specific legislation)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="container">
              <h2
                className="f-primary-b mar-bot-50"
                style={{ color: "#f48739" }}
              >
                <strong className="f-secondary-l upcoming">Upcoming</strong>{" "}
                Events
              </h2>
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={drugPrevention}
                    className="img-fluid upcoming_events_latest"
                    alt="Image 1"
                  />
                  <div className="col-md-8 mt-3">
                    <h4 className="events-headers home_events">
                      Drug prevention & Awareness
                    </h4>
                    <p className="el">
                      - Lawyer Mrs. Mrs Vrindha Mohan (specialized in women
                      specific legislation)
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    src={womenRights}
                    className="img-fluid upcoming_events_latest"
                    alt="Image 2"
                  />
                  <div className="col-md-9 mt-3">
                    <h4 className="events-headers home_events">
                      Sexual Harassment in Workplace
                    </h4>
                    <p className="el">
                      – By HR leader from MNC [Ms Punitha Antony] – Logistics
                      being worked on.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    src={sexualHarrasment}
                    className="img-fluid upcoming_events_latest w-75"
                    alt="Image 3"
                  />
                  <div className="col-md-9 mt-3">
                    <h4 className="events-headers home_events">
                      Laws & Legislative Rights related to Women Safety
                    </h4>
                    <p className="el">
                      - Lawyer Mrs. Mrs Vrindha Mohan (specialized in women
                      specific legislation)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="carouselExampleIndicators2"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              style={{ backgroundColor: "white" }}
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              style={{ backgroundColor: "white" }}
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              style={{ backgroundColor: "white" }}
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              style={{ backgroundColor: "white" }}
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Latest</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-4 mb-3">
                    <img
                      src={logo9}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-6 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Carrer Awarness Programme
                    </h4>
                    <p className="el">
                      <i className="fa fa-calendar"></i> 23-02-2019
                    </p>
                    <p className="el">
                      <i className="fa fa-map-marker"></i> PEC
                    </p>
                    <p className="el">
                      A career awareness program was organised by the IEDC cell
                      in collaboration with the Le Banyan de PEC on the topic
                      'Engineering education/entrepreneurship - scope,
                      challenges in Indian and global scenario' by Mr.Sivakumar
                      Govindassamy on 8th February , 2019. Mr.Sivakumar
                      discussed about various technologies in smartphones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Latest</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-4 mb-3">
                    <img
                      src={logo10}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-6 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Health and Hygiene Environment
                    </h4>
                    <p className="el">
                      <i className="fa fa-calendar"></i> 23-02-2019
                    </p>
                    <p className="el">
                      <i className="fa fa-map-marker"></i> Sadhana forest,
                      Auroville
                    </p>
                    <p className="el">
                      Le Banyan de PEC in association with Health and Hygiene
                      Environment club of PEC is organizing a visit to Sadhana
                      forest, Auroville on 23.2.2019 to sensitize the students
                      about green environment and minimalism of natural
                      resources.In this connection PEC bus is arranged near Mass
                      hotel (opposite) at 6.15 AM.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Latest</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-4 mb-3">
                    <img
                      src={logo8}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-6 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Women Safety - Cyber Violence and Threats
                    </h4>
                    <div className="dates-events">
                      <i
                        className="fa fa-calendar icons mt-2"
                        style={{ color: "white" }}
                      ></i>
                      <p className="el mx-2">21–03-2019, 2:00 to 4:00PM IST</p>
                    </div>

                    <p className="el">
                      <i className="fa fa-map-marker"></i> Seminar Hall,
                      Chemical Engg. Block, PEC
                    </p>
                    <p className="el">
                      Le Banyan de PEC in association with Women cell of PEC is
                      organizing a talk on “Women Safety in the Era of Cyber
                      Violence and Threats” for PEC Students (final year girls
                      students).
                      <b>
                        Dr. Vidhya Ramkumar, Chairperson, Child Welfare
                        Committee / Sexual harassment
                      </b>{" "}
                      at work place for Women, Pondicherry Government - will
                      address the Students
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container mb-5">
                <div className="row justify-content-start events_upcoming">
                  <h2
                    className="f-primary-b mar-bot-50"
                    style={{ color: "#f48739" }}
                  >
                    <strong className="f-secondary-l upcoming">Latest</strong>{" "}
                    Events
                  </h2>
                  <div className="col-md-4 mb-3">
                    <img
                      src={upcomingEvents}
                      className="img-responsive img-thumbnail h-100"
                      alt="education"
                    />
                  </div>
                  <div className="col-md-8 pad-lef-50">
                    <h4 className="events-headers home_events">
                      Nation Building Through Innovation and Entrepreneurship
                    </h4>
                    <div className="dates-events">
                      <i
                        className="fa fa-calendar icons mt-2"
                        style={{ color: "white" }}
                      ></i>
                      <p className="el mx-2">3rd April 3.00 p.m</p>
                    </div>

                    <p className="el">
                      <i className="fa fa-map-marker"></i> PTU Auditorium, PEC
                    </p>
                    <p className="el">
                      Satyanarayanan V (Satya) is a Technology Professional,
                      Thought Leader, Speaker and aspiring Author. He has 34
                      years of experience in the IT industry. He is the founder
                      of Yashmia Consulting and Technologies, an IT firm
                      focusing on technology services and consulting. He is also
                      the co-founder of CTO Bridge Solutions. Before turning an
                      entrepreneur, he has held diverse global leadership roles
                      viz. Engineering, Technology support, HR and Corporate
                      Quality. Earlier, he has worked with CSS Corp (now
                      Movate), the Royal Court Affairs, Oman and CMC Ltd, India.
                    </p>
                  </div>
                  <p className="el col-12">
                    Satya graduated with distinction in Electronics and
                    Communication Engineering from Pondicherry Engineering
                    College. He holds a Postgraduate Diploma in Business
                    Management from the Institute of Management Technology,
                    Ghaziabad and a PMP certification from the Project
                    Management Institute, USA.
                  </p>
                  <p className="el col-12">
                    An Internationally Elo rated Chess Player, his major
                    accomplishments have been - finishing runner up to
                    Viswanathan Anand in the National Sub-junior Chess
                    championship at Goa (1983) and winning the All India Inter
                    University Championship for the Madras University at Bodh
                    Gaya (1985). He had also secured third rank at Mysore (1981)
                    and Hyderabad (1982) in the National Sub-junior
                    Championships. In a career spanning 13 years from 1974 to
                    1987, he has many more championships and achievements to his
                    credit. Satya loves reading books on wide ranging subjects
                    viz. Philosophy, Spirituality, Quantum Physics, History and
                    Biography
                  </p>
                  <p className="el col-12">
                    Satya is keen to contribute for India and in this endeavour,
                    has plans to meet a hundred thousand youth / students in the
                    coming years.
                  </p>
                  <p className="el col-12">
                    Online steaming via YouTube will be available.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                        <span style={{ backgroundColor: "gray" }} className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                        <span style={{ backgroundColor: "gray" }} className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button> */}
        </div>
        <Testimonial />
       {/* {showPopup && getUserDetails && <PopupModal onClose={togglePopup} />} */}
       {showPopup && <PopupModal onClose={togglePopup} />}
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default MainHome;
