import React, { useEffect, useState } from "react";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import "./viewStyles/executiveBoard.css";
import logo from "../assets/images/users/no-image.jpg";
import { BsTwitter } from "react-icons/bs";
import { BsGoogle } from "react-icons/bs";
import { BsHandThumbsUpFill } from "react-icons/bs";
import PDF from "../assets/newsletter/newsletter.pdf";
import { Data_Members, sideTab } from "./execBoardDetails";
import Footer from "../components/Footer";

const ExecBoard = () => {
  // Social Media Icons
  const GOOGLE = <BsTwitter className="social" size={20} />;
  const TWITTER = <BsGoogle className="social" size={20} />;
  const THUMBSUP = <BsHandThumbsUpFill className="social" size={20} />;

  const [fetchMembersDetails, updatedMembersDetails] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedHeading, setSelectedHeading] = useState();
  const [activeItem, setActiveItem] = useState("President");

  const fetchTitle = (event) => {
    const selectedTitle = sideTab.find((data) => data.text === event);
    setSelectedHeading(selectedTitle);
    setActiveItem(event);
    console.log(event);
    const fetchMemebers = updatedMembersDetails(selectedTitle.members);
    return fetchMemebers;
  };

  useEffect(() => {
    const defaultList = Data_Members.filter(
      (data) => data.title === "President"
    );
    setSelectedTitle(defaultList);
  }, []);

  const roleNull = <div style={{ height: "20px" }}></div>;

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Team" />
      {/* <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <form>
              <div className="form-group">
                <label htmlFor="exampleSelect mb-3">Board Memebers</label>
                <div htmlFor="exampleSelect mt-3">
                  Selected Board Member: {activeItem}{" "}
                </div>
                <select
                  className="form-control mt-2"
                  id="exampleSelect"
                  value={activeItem}
                  onChange={(e) => fetchTitle(e.target.value)}
                >
                  {sideTab.map((data) => (
                    <option key={data.id} value={data.text}>
                      {data.text}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
      <section id="team" className="team section">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-center align-items-center">
            {fetchMembersDetails.map((data) => (
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="100"
                key={data}
              >
                <div className="team-member">
                  <div className="member-img">
                    <img
                      src={!data.imgSrc ? logo : data.imgSrc}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter-x"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>{data.name.toLocaleUpperCase()}</h4>
                    <span>{data.role ? data.role : roleNull}</span>
                    <span>{data.college}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row gy-4 d-flex justify-content-center align-items-center">
            {fetchMembersDetails.length === 0 &&
              selectedTitle.map((data) => (
                <div
                  className="col-lg-3 col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  key={data}
                >
                  <div className="team-member">
                    <div className="member-img">
                      <img
                        src={!data.imgSrc ? logo : data.imgSrc}
                        className="img-fluid"
                        alt=""
                      />
                      <div className="social">
                        <a href="">
                          <i className="bi bi-twitter-x"></i>
                        </a>
                        <a href="">
                          <i className="bi bi-facebook"></i>
                        </a>
                        <a href="">
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a href="">
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div className="member-info">
                      <h4>{data.name.toLocaleUpperCase()}</h4>
                      <span>{data.role ? data.role : roleNull}</span>
                      <span>{data.college}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section> */}
      <section className="executive-board" style={{ height: "100%" }}>
        <div className="container">
          <div class="row justify-content-start">
            <div class="col-4">
              <div className="col-lg-10 members-tab-menu">
                {sideTab.map((data) => (
                  <div className="list-group" key={data.id}>
                    <a
                      onClick={() => fetchTitle(data.text)}
                      style={{ cursor: "pointer" }}
                      className={
                        activeItem === data.text
                          ? "list-group-item active"
                          : "list-group-item"
                      }
                    >
                      {data.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-4">
              <div className="col-lg-8 d-flex align-items-center">
                <div className="row">
                  <div className="container info-details">
                    <h1 className="text-center">
                      {fetchMembersDetails.length === 0
                        ? "PRESIDENT"
                        : selectedHeading.text.toLocaleUpperCase()}
                    </h1>
                  </div>
                  {fetchMembersDetails.map((data) => (
                    // <div className={fetchMembersDetails.length < 2 ? `col-xl-12 mt-2` : `col-xl-4 mt-2`} >
                    <div className={`col-xl-4 mt-2 `}>
                      <div
                        className="icon-box d-flex flex-column justify-content-center align-items-center"
                        style={{ backgroundColor: "black", height: "100%" }}
                      >
                        <div
                          className="card col-12"
                          style={{
                            backgroundColor: "rgb(59, 58, 58)",
                            height: "100%",
                          }}
                        >
                          <img
                            src={data.imgSrc ? data.imgSrc : logo}
                            className="card-img-top execBoardImg"
                            alt="logo-1"
                          />
                          <div className="card-body" key={data.id}>
                            <h5 className="board-members">
                              {data.name.toLocaleUpperCase()}
                            </h5>
                            <h6 className="board-role">{data.role}</h6>
                            <h6 className="board-position">
                              {data.description}
                            </h6>
                            <p id="college-pec">{data.college}</p>
                            <a class="card-link">{TWITTER}</a>
                            <a class="card-link">{GOOGLE}</a>
                            <a class="card-link">{THUMBSUP}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {fetchMembersDetails.length === 0 &&
                    selectedTitle.map((data) => (
                      <div className="col-xl-4 mt-2">
                        <div
                          className="icon-box d-flex flex-column justify-content-center align-items-center"
                          style={{ backgroundColor: "black", height: "100%" }}
                        >
                          <div
                            className="card col-12"
                            style={{
                              backgroundColor: "rgb(59, 58, 58)",
                              height: "100%",
                            }}
                          >
                            <img
                              src={data.imgSrc ? data.imgSrc : logo}
                              className="card-img-top execBoardImg"
                              alt="logo-1"
                            />
                            <div className="card-body" key={data.id}>
                              <h5 className="board-members">{data.name}</h5>
                              <h6 className="board-role">{data.role}</h6>
                              <h6 className="board-position">
                                {data.description}
                              </h6>
                              <p id="college-pec">{data.college}</p>
                              <a class="card-link">{TWITTER}</a>
                              <a class="card-link">{GOOGLE}</a>
                              <a class="card-link">{THUMBSUP}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default ExecBoard;
