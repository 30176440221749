import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import "./viewStyles/login.css";
import { Link, useNavigate } from "react-router-dom";
import "./viewStyles/login.css";
import { banyanLoginApi, toastError } from "../serviceApi/Auth-Services";
import swal from "sweetalert";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";
import PopupModal from "./popupModal";

const Login = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const firstInputRef = useRef();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: true,
    touched: {
      email: false,
      password: false,
    },
  });

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      // Handle checkboxes
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      // Update the form data
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;

    // Mark the field as touched
    setFormData({
      ...formData,
      touched: {
        ...formData.touched,
        [name]: true,
      },
    });
  };

  const isFieldValid = (field) => {
    return formData[field] !== "";
  };

  const disableButton = (formData.email && formData.password) === "";

  const onLoginHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (disableButton) {
      swal("Oops", `Please fill in the required fields`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      email: formData.email,
      password: formData.password,
      rememberMe: formData.rememberMe,
    };

    if (payload.password === "welcome@123") {
      navigate("/update-password", { state: { email: payload.email } });
      return;
    }
    
    banyanLoginApi(payload)
      .then((response) => {
        toast.dismiss();
        if (response.status === "success") {
          setIsLoading(false);
          localStorage.setItem("userDetails", response._id);
          navigate("/");
          toast.success(response.message);
        } else {
          setIsLoading(false);
          setIsError(response.message);
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error("A network error occurred");
        setIsLoading(false);
        console.log(err);
      });

    // console.log(payload.password);
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Members Area" />
      {isLoading && <SpinnerWrapper />}
      <div className="site-login container">
        <div className="row">
          <div className="login-form-container col-md-6 mb-5">
            <div className="f-title-description b-title-description">Login</div>
            <form
              onSubmit={onLoginHandler}
              id="login-form"
              className="form-horizontal"
            >
              <div className="form-group field-loginform-email">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      for="inputField1"
                      className={`col-md-3 ${
                        formData.touched.email && !isFieldValid("email")
                          ? "col-form-label red-label"
                          : "col-form-label text-white"
                      }`}
                    >
                      Email
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      spellCheck="false"
                      ref={firstInputRef}
                      className={`form-control ${
                        formData.touched.email && !isFieldValid("email")
                          ? "red-border"
                          : "form-control custom-shadow"
                      }`}
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    {formData.touched.email && !isFieldValid("email") && (
                      <div className="text-danger err_msg">
                        Email cannot be blank.
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-sm-9">
                  <div className="form-group">
                    <div className="row">
                      <label className={`${formData.touched.email && !isFieldValid('email') ? 'red-label' : 'control-label text-white'}`}>Email</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className={`form-control ${formData.touched.email && !isFieldValid('email') ? 'is-invalid' : 'form-control custom-shadow'
                            }`}
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                        />
                        {formData.touched.email && !isFieldValid('email') && (
                          <div className="invalid-feedback">Email cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="form-group field-loginform-password mt-4">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      for="inputField1"
                      className={`col-md-3 ${
                        formData.touched.password && !isFieldValid("password")
                          ? "col-form-label red-label"
                          : "col-form-label text-white"
                      }`}
                    >
                      Password
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        className={`form-control custom-focus-style ${
                          formData.touched.password && !isFieldValid("password")
                            ? "red-border"
                            : "form-control custom-shadow"
                        }`}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                      />
                      {formData.touched.password &&
                        !isFieldValid("password") && (
                          <div className="text-danger err_msg">
                            Password cannot be blank.
                          </div>
                        )}
                      {formData.touched.password &&
                      !isFieldValid("password") ? (
                        ""
                      ) : (
                        <i
                          style={{ color: "white" }}
                          className={`fa ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-9">
                  <div className="form-group">
                    <div className="row">
                      <label className={`${formData.touched.password && !isFieldValid('password') ? 'red-label' : 'control-label text-white'}`}>Password</label>
                      <div className="col-md-10">
                        <div className="password-input-container">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={formData.password}
                            className={`form-control ${formData.touched.password && !isFieldValid('password') ? 'is-invalid' : 'form-control custom-shadow'
                              }`}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          {formData.touched.password && !isFieldValid('password') && (
                            <div className="invalid-feedback">Password cannot be blank.</div>
                          )}
                          {formData.touched.password && !isFieldValid('password') ? "" : <i style={{ color: 'white' }}
                            className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                            onClick={() => setShowPassword(!showPassword)}
                          ></i>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="form-group field-loginform-remeberme mt-4">
                <div className="row">
                  <div className="col-md-8 mx-2">
                    <input
                      type="checkbox"
                      name="rememberMe"
                      onChange={handleInputChange}
                      checked={formData.rememberMe}
                      id="rememberMe"
                    />{" "}
                    <label
                      htmlFor="loginform-rememberme"
                      className="text-white"
                    >
                      Remember Me
                    </label>
                    <div className="help-block help-block-error "></div>
                  </div>
                </div>
                {/* <div className="col-sm-9">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-10">
                        <input
                          type="checkbox"
                          name="rememberMe"
                          onChange={handleInputChange}
                          checked={formData.rememberMe}
                          id="rememberMe"
                        />
                        {" "}<label htmlFor="loginform-rememberme" className="text-white">Remember Me</label>
                        <div className="help-block help-block-error ">
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="form-group mb-4 mt-4">
                <div className="col-lg-offset-3 col-lg-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-success"
                    name="login-button"
                    style={{
                      backgroundColor: "#5CB85C",
                      width: " 100%",
                    }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                  >
                    Login
                  </button>{" "}
                </div>
                {/* <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div
                      className="modal-content bg-dark text-white"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="modal-header"
                        style={{ borderBottom: "none" }}
                      >
                        <h5 className="modal-title" id="exampleModalLabel">
                          Login Successfull
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        We are 80G certified. If you need 80G, please send the
                        below details to our treasurer, Prof. Jayabharathi @
                        9443292660 via Whatsapp, jayabharathy@ptuniv.edu.in via
                        email.
                      </div>
                      <ul className="mx-3">
                        <li>Name</li>
                        <li>Father's name</li>
                        <li>Address</li>
                        <li>PAN</li>
                        <li>Amount</li>
                        <li>Transaction number</li>
                        <li>Date of transaction</li>
                        <li>Email address!</li>
                      </ul>
                      <div
                        className="modal-footer"
                        style={{ borderTop: "none" }}
                      >
                        <button
                          type="submit"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </form>{" "}
            <div className="b-title-description col-lg-10"></div>
            <div className="row justify-content-between">
              <div className="col-4">
                <Link
                  style={{ textDecoration: "none" }}
                  className="links-login"
                  to="/request-password-reset"
                >
                  Forget Password?
                </Link>
              </div>
              <div className="col-6">
                <Link
                  style={{ textDecoration: "none" }}
                  className="links-login"
                  to="/register"
                >
                  Become a Member
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 login-right-container mb-5">
            <div className="f-title-description b-title-description">
              Members Area Features
            </div>
            <p className="mem_area">
              Become a member to access all the features.
            </p>
            <ul>
              <li className="mem_area">Be a Member</li>
            </ul>
            <div className="col-lg-6 col-lg-offset-3">
              <Link
                className="btn btn-success btn-block"
                style={{
                  backgroundColor: "#5CB85C",
                  width: " 100%",
                }}
                to={"/register"}
              >
                Become a Member
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Login;
