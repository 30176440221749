import React from "react";
// import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {  BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainHome from "./views/home";
import About from "./views/aboutUs";
import Objective from "./views/objective";
import ExecBoard from "./views/executiveBoard";
import Events from "./views/events";
import Contactus from "./views/contactUs";
import Login from "./views/login";
import Register from "./views/register";
import ForgotPassword from "./views/forgotPassword";
import SampleBoard from "./views/OTP";
import Admin from "./views/admin";
import OneTimePassword from "./views/OTP";
import UpdatePassword from "./views/updatePassword";
// import Home from "../src/views/home.js";
import { ProtectedRoutes } from "./serviceApi/Auth-Services";
import { Toaster } from "react-hot-toast";
import PrivateNavigation from "./views/navFooter";
import ExcelView from "./views/excelView";
import NotFoundPage from "./views/pageNotFound";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ScholarshipForm from "./views/scholarship";

// const router = createBrowserRouter([
//   { path: "/", element: <MainHome /> },
//   { path: "/about", element: <About /> },
//   { path: "/objective", element: <Objective /> },
//   { path: "/exec-board", element: <ExecBoard /> },
//   { path: "/events", element: <Events /> },
//   { path: "/contact-us", element: <Contactus /> },
//   { path: "/login", element: <Login /> },
//   { path: "/register", element: <Register /> },
//   { path: "/request-password-reset", element: <ForgotPassword /> },
//   { path: "/admin", element: <ProtectedRoutes path="/admin" element={<Admin />} /> },
//   { path: "/otp-pin", element: <OneTimePassword /> },
//   { path: "/update-password", element: <UpdatePassword /> },
// ]);

function App() {
  return (
    // <React.Fragment>
    //   {/* <Router>
    //     <Routes>
    //       <ProtectedRoute path="/admin" component={Admin} />
    //     </Routes>
    //   </Router> */}
    //   <Toaster position="top-center" reverseOrder={false} />
    //   <RouterProvider router={router} />
    // </React.Fragment>

    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<MainHome />} />
        <Route path="/about" element={<About />} />
        <Route path="/objective" element={<Objective />} />
        <Route path="/exec-board" element={<ExecBoard />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/request-password-reset" element={<ForgotPassword />} />
        <Route path="/admin" element={<ProtectedRoutes path="/admin" element={<Admin />} />} />
        <Route path="/otp-pin" element={<OneTimePassword />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/scholarship-form" element={<ScholarshipForm />} />
        {/* <Route path="/activities" element={<ExcelView />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
