import React from "react";
import ".//viewStyles/events.css";
import Logo from "../assets/images/events/cap042017-thumb.jpg";
import Logo2 from "../assets/images/events/2-thumb.jpg";
import Logo3 from "../assets/images/events/3-thumb.jpg";
import Logo4 from "../assets/images/events/1-thumb.jpg";
import Logo5 from "../assets/images/events/4-thumb.jpg";
import Logo6 from "../assets/images/events/5-thumb.jpg";
import Logo7 from "../assets/images/events/6-thumb.jpg";
import Logo8 from "../assets/images/events/7-thumb.jpg";
import Logo9 from "../assets/images/events/8-thumb.jpg";
import Logo10 from "../assets/images/events/9-thumb.jpg";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import SubHeader from "./subheader";

const EVENTS_GALLERY = [
  {
    title: "Career Awareness programme",
    subTitle: "22nd April 2017",
    image: { Logo },
  },
  {
    title: "Clean and Green PEC",
    subTitle: "26th January 2017 (Thursday)",
    image: { Logo2 },
  },
  {
    title: "CAP Programmee",
    subTitle: "27th January 2017 (Friday)",
    image: { Logo3 },
  },
  {
    title: "Birthday Celebration at Udhavi Karangal",
    subTitle: "7th January 2017 (Saturday)",
    image: { Logo4 },
  },
  {
    title: "CAP Programmee",
    subTitle: "1st Feburary 2017 (Wednesday)",
    image: { Logo5 },
  },
  {
    title: "CAP Programmee",
    subTitle: "2nd Feburary 2017 (Thursday)",
    image: { Logo6 },
  },
  {
    title: "Health & Hygiene club",
    subTitle: "16th Feburary 2017 (Thursday)",
    image: { Logo7 },
  },
  {
    title: "Women's Day Celebration ",
    subTitle: "10th March 2017 (Friday)",
    image: { Logo8 },
  },
  {
    title: "Women Safety and Precautions",
    subTitle: "14th March 2017 (Tuesday)",
    image: { Logo9 },
  },
  {
    title: "Birthday Celebration",
    subTitle: "11th March 2017 (Saturday)",
    image: { Logo10 },
  },
];

const Events = () => {
  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Events" />
      <div className="container b-container-top-50 gallery-container">
        <div className="tz-gallery">
          <div className="row"></div>
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo}
                    alt="Career Awareness programme"
                  />
                </a>
                <div className="caption">
                  <h3>Career Awareness programme</h3>
                  <p className="events_dates">22nd April 2017</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo2}
                    alt="Clean and Green PEC"
                  />
                </a>
                <div className="caption">
                  <h3>Clean and Green PEC</h3>
                  <p className="events_dates">26th January 2017 (Thursday)</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo3}
                    alt="CAP Programmee"
                  />
                </a>
                <div className="caption">
                  <h3>CAP Programmee</h3>
                  <p className="events_dates">27th January 2017 (Friday)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo4}
                    alt="Birthday Celebration at Udhavi Karangal"
                  />
                </a>
                <div className="caption">
                  <h3>Birthday Celebration at Udhavi Karangal</h3>
                  <p className="events_dates">7th January 2017 (Saturday)</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo5}
                    alt="CAP Programmee "
                  />
                </a>
                <div className="caption">
                  <h3>CAP Programmee </h3>
                  <p className="events_dates">1st Feburary 2017 (Wednesday)</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo7}
                    alt="Health & Hygiene club "
                  />
                </a>
                <div className="caption">
                  <h3>Health & Hygiene club </h3>
                  <p className="events_dates">16th Feburary 2017 (Thursday)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo8}
                    alt="Women's Day Celebration "
                  />
                </a>
                <div className="caption">
                  <h3>Women's Day Celebration </h3>
                  <p className="events_dates">10th March 2017 (Friday)</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo10}
                    alt="Women Safety and Precautions "
                  />
                </a>
                <div className="caption">
                  <h3>Women Safety and Precautions </h3>
                  <p className="events_dates">14th March 2017 (Tuesday)</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div
                class="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a class="lightbox">
                  <img
                    class="img-responsive"
                    src={Logo9}
                    alt="Birthday Celebration"
                  />
                </a>
                <div class="caption">
                  <h3>Birthday Celebration </h3>
                  <p className="events_dates">11th March 2017 (Saturday)</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div className="col-sm-6 col-md-4">
              <div
                className="thumbnail"
                style={{ backgroundColor: "rgb(59, 58, 58)" }}
              >
                <a className="lightbox">
                  <img
                    className="img-responsive"
                    src={Logo6}
                    alt="CAP Programmee "
                  />
                </a>
                <div className="caption">
                  <h3>CAP Programmee </h3>
                  <p className="events_dates">2nd Feburary 2017 (Thursday)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Events;
