import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import "./viewStyles/register.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { banyanRegisterApi } from "../serviceApi/Auth-Services";
import swal from "sweetalert";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";
import { TOP_EMAIL_DOMAINS, topEmailDomains } from "../serviceApi/constant";

const Register = () => {
  const navigate = useNavigate();
  const firstInputRef = useRef();
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
    mobileNumber: "",
    gender: "",
    address: "",
    occupation: "",
    course: "",
    branch: "",
    yearOfPassing: "",
    communicationMode: "email",
    memberShipType: "L",
    interestedTowards: [
      "Education",
      "Women Empowerment",
      "Welfare",
      "Environment",
    ],
    contribution: "",
    role: "member",
    touched: {
      name: false,
      email: false,
      password: false,
      repeatPassword: false,
      mobileNumber: false,
      occupation: false,
      course: false,
      branch: false,
      yearOfPassing: false,
      interestedTowards: false,
      address: false,
      contribution: false,
    },
  });

  const interestedOptions = [
    "Education",
    "Women Empowerment",
    "Welfare",
    "Environment",
  ];

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "radio") {
      // Handle checkboxes for gender selection
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (type === "checkbox") {
      if (checked) {
        // If checked, add the value to the array
        setFormData({
          ...formData,
          interestedTowards: [...formData.interestedTowards, value],
        });
      } else {
        // If unchecked, remove the value from the array
        setFormData({
          ...formData,
          interestedTowards: formData.interestedTowards.filter(
            (item) => item !== value
          ),
        });
      }
    } else {
      // Handle other input fields
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;

    // Mark the field as touched
    setFormData({
      ...formData,
      touched: {
        ...formData.touched,
        [name]: true,
      },
    });
  };

  const isFieldValid = (field) => {
    return formData[field] !== "";
  };
  const fromTouchedState =
    formData.name &&
    formData.email &&
    formData.password &&
    formData.repeatPassword &&
    formData.mobile &&
    formData.occupation &&
    formData.course &&
    formData.branch &&
    formData.interestedTowards &&
    formData.yearOfPassing &&
    formData.address &&
    formData.contribution;
  const disableButton = fromTouchedState === "";

  const onSignupHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const containsNumbers = /\d/.test(formData.name);
    const containsNameLength = formData.name.length >= 3;
    toast.dismiss();
    if (disableButton) {
      swal("Oops", `Please fill in the required fields`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (containsNameLength) {
      console.log("Name is valid");
    } else {
      swal("Oops", `Please enter a valid name`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (containsNumbers) {
      swal("Oops", `Numbers are not allowed in the name field`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (formData.password !== formData.repeatPassword) {
      swal("Oops", `Password and Repeat Password does not match`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (formData.mobileNumber.length > 10) {
      swal("Oops", `Mobile number should not be more than 10 digits`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (formData.interestedTowards.length === 0) {
      swal("Oops", `Scheme Type cannot be blank.`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    const email = formData.email.trim().toLowerCase();
    const domain = email.substring(email.lastIndexOf("@") + 1);

    let isValidDomain = false;
    for (const domainKey in TOP_EMAIL_DOMAINS) {
      if (domain === domainKey) {
        isValidDomain = true;
        break;
      }
    }
    console.log(isValidDomain);

    if (!isValidDomain) {
      setIsLoading(false);
      swal("Oops", `Please enter a valid email address`, "error", {
        button: "Try Again!",
      });
      return;
    }

    // if (!formData.email.includes("@gmail.com")) {
    //   setIsLoading(false);
    //   swal("Oops", `Please enter a valid email address`, "error", {
    //     button: "Try Again!",
    //   });
    //   return;
    // }

    const payload = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      repeatPassword: formData.repeatPassword,
      mobileNumber: formData.mobileNumber,
      gender: formData.gender,
      address: formData.address,
      occupation: formData.occupation,
      course: formData.course,
      branch: formData.branch,
      yearOfPassing: formData.yearOfPassing,
      communicationMode: formData.communicationMode,
      membershipType: formData.memberShipType,
      interestedTowards: formData.interestedTowards,
      contribution: parseInt(formData.contribution),
      role: formData.role,
    };

    banyanRegisterApi(payload)
      .then((response) => {
        toast.dismiss();
        console.log("register response ==> ", response.message);
        if (response.status === "success") {
          setIsLoading(false);
          toast.success(response.message);
          navigate("/otp-pin", {
            state: { id: response?._id, navto: "/admin" },
          });
          //  localStorage.setItem("userDetails", response._id);
        } else {
          setIsError(response.message);
          setIsLoading(false);
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error("A network error occurred");
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Become a Member" />
      {isLoading && <SpinnerWrapper />}
      <div className="site-signup container ">
        <div className="row">
          <div className="signup-form-container col-md-12">
            <div className="f-title-description b-title-description title-signup">
              Signup
            </div>
            <form onSubmit={onSignupHandler}>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.name && !isFieldValid("name")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Name
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.name && !isFieldValid("name")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="name"
                          // placeholder="Enter Your Full Name"
                          type="text"
                          value={formData.name}
                          ref={firstInputRef}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          maxLength={50}
                          autoComplete="off"
                        />
                        {formData.touched.name && !isFieldValid("name") && (
                          <div className="text-danger err_msg">
                            Name cannot be blank.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4  right-aligned-label ${
                          formData.touched.occupation &&
                          !isFieldValid("occupation")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Occupation
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.occupation &&
                            !isFieldValid("occupation")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="occupation"
                          // placeholder="Enter Your occupation"
                          type="occupation"
                          value={formData.occupation}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                        />
                        {formData.touched.occupation &&
                          !isFieldValid("occupation") && (
                            <div className="text-danger err_msg">
                              Occupation cannot be blank.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.email && !isFieldValid("email")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Email
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.email && !isFieldValid("email")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="email"
                          // placeholder="Enter Your Email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                        />
                        {formData.touched.email && !isFieldValid("email") && (
                          <div className="text-danger err_msg">
                            Email cannot be blank.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.course && !isFieldValid("course")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Course
                      </label>
                      <div className="col-md-7">
                        <input
                          // placeholder="Enter Your Degree"
                          name="course"
                          type="text"
                          value={formData.course}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          className={`form-control ${
                            formData.touched.course && !isFieldValid("course")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          autoComplete="off"
                        />
                        {formData.touched.course && !isFieldValid("course") && (
                          <div className="text-danger err_msg">
                            Course cannot be blank.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.password && !isFieldValid("password")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Password
                      </label>
                      <div className="col-md-7">
                        <div className="password-input-container">
                          <input
                            type={showPassword ? "text" : "password"}
                            // placeholder="Enter Strong Password"
                            name="password"
                            value={formData.password}
                            className={`form-control ${
                              formData.touched.password &&
                              !isFieldValid("password")
                                ? "red-border"
                                : "form-control custom-shadow"
                            }`}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            autoComplete="off"
                          />
                          {formData.touched.password &&
                            !isFieldValid("password") && (
                              <div className="text-danger err_msg">
                                Password cannot be blank.
                              </div>
                            )}
                          {formData.touched.password &&
                          !isFieldValid("password") ? (
                            ""
                          ) : (
                            <i
                              style={{ color: "white" }}
                              className={`fa ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={() => setShowPassword(!showPassword)}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.branch && !isFieldValid("branch")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Branch
                      </label>
                      <div className="col-md-7">
                        <input
                          // placeholder="Enter Your Branch/Specialization"
                          name="branch"
                          type="text"
                          value={formData.branch}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          className={`form-control ${
                            formData.touched.branch && !isFieldValid("branch")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          autoComplete="off"
                        />
                        {formData.touched.branch && !isFieldValid("branch") && (
                          <div className="text-danger err_msg">
                            Branch cannot be blank.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.repeatPassword &&
                          !isFieldValid("repeatPassword")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Confirm Password
                      </label>
                      <div className="col-md-7">
                        <div className="password-input-container">
                          <input
                            type={showRepeatPassword ? "text" : "password"}
                            // placeholder="Repeat Your Password"
                            name="repeatPassword"
                            value={formData.repeatPassword}
                            className={`form-control ${
                              formData.touched.repeatPassword &&
                              !isFieldValid("repeatPassword")
                                ? "red-border"
                                : "form-control custom-shadow"
                            }`}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            autoComplete="off"
                          />
                          {formData.touched.repeatPassword &&
                            !isFieldValid("repeatPassword") && (
                              <div className="text-danger err_msg">
                                Repeat Password cannot be blank.
                              </div>
                            )}
                          {formData.touched.repeatPassword &&
                          !isFieldValid("repeatPassword") ? (
                            ""
                          ) : (
                            <i
                              style={{ color: "white" }}
                              className={`fa ${
                                showRepeatPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={() =>
                                setShowRepeatPassword(!showRepeatPassword)
                              }
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.yearOfPassing &&
                          !isFieldValid("yearOfPassing")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Year of passing
                      </label>
                      <div className="col-md-7">
                        <input
                          // placeholder="Year in which you passed"
                          name="yearOfPassing"
                          type="text"
                          value={formData.yearOfPassing}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          className={`form-control ${
                            formData.touched.yearOfPassing &&
                            !isFieldValid("yearOfPassing")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          autoComplete="off"
                        />
                        {formData.touched.yearOfPassing &&
                          !isFieldValid("yearOfPassing") && (
                            <div className="text-danger err_msg">
                              Year Of Passing cannot be blank.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.mobileNumber &&
                          !isFieldValid("mobileNumber")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Mobile
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.mobileNumber &&
                            !isFieldValid("mobileNumber")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="mobileNumber"
                          // placeholder="Enter Your Mobile Number"
                          type="number"
                          value={formData.mobileNumber}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                        />
                        {formData.touched.mobileNumber &&
                          !isFieldValid("mobileNumber") && (
                            <div className="text-danger err_msg">
                              Mobile cannot be blank.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Communication
                      </label>
                      <div className="col-md-6 mt-2">
                        <label className="radio-inline com-mode text-white">
                          <input
                            className="radio"
                            type="radio"
                            name="communicationMode"
                            id="email"
                            value="email"
                            checked={formData.communicationMode === "email"}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />{" "}
                          Email
                        </label>
                        <label className="radio-inline mx-3 text-white">
                          <input
                            className="radio"
                            type="radio"
                            name="communicationMode"
                            id="mobile"
                            value="mobile"
                            checked={formData.communicationMode === "mobile"}
                            onChange={handleInputChange}
                          />{" "}
                          Mobile
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Gender
                      </label>
                      <div className="col-md-6 mt-2">
                        <label className="radio-inline text-white">
                          <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={formData.gender === "male"}
                            onChange={handleInputChange}
                          />{" "}
                          Male
                        </label>
                        <label className="radio-inline mx-3 text-white">
                          <input
                            type="radio"
                            id="female"
                            name="gender"
                            value="female"
                            checked={formData.gender === "female"}
                            onChange={handleInputChange}
                          />{" "}
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Membership
                      </label>
                      <div className="col-md-7">
                        <div
                          id="signupform-mem-type"
                          className="text-white mt-2"
                        >
                          <div className="radio">
                            <label className="mem-type">
                              <input
                                className="radio"
                                type="radio"
                                name="memberShipType"
                                id="lifeTime"
                                value="L"
                                checked={formData.memberShipType === "L"}
                                onChange={handleInputChange}
                              />{" "}
                              Life Membership fee one time payment Rs. 7500
                            </label>
                          </div>
                          {/* <div className="radio">
                            <label className="mem-type">
                              <input
                                className="radio"
                                type="radio"
                                name="memberShipType"
                                id="annual"
                                value="A"
                                checked={formData.memberShipType === "A"}
                                onChange={handleInputChange}
                              />{" "}
                              Annual (Rs 1500.00)
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.address && !isFieldValid("address")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Address
                      </label>
                      <div className="col-md-7">
                        <textarea
                          style={{
                            height: "100px",
                          }}
                          type="text"
                          name="address"
                          className={`form-control ${
                            formData.touched.address && !isFieldValid("address")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          rows="4"
                          cols="50"
                          value={formData.address}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                          // placeholder="Enter Your full address"
                        />
                        {formData.touched.address &&
                          !isFieldValid("address") && (
                            <div className="text-danger err_msg">
                              Address cannot be blank.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <div className="form-group row">
                      <label
                        for="inputField7"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Membership
                      </label>
                      <div className="col-md-7">
                        <div
                          id="signupform-mem-type"
                          className="text-white mt-2"
                        >
                          <div className="radio">
                            <label className="mem-type">
                              <input
                                className="radio"
                                type="radio"
                                name="memberShipType"
                                id="lifeTime"
                                value="L"
                                checked={formData.memberShipType === "L"}
                                onChange={handleInputChange}
                              />{" "}
                              Life time (Rs 7500.00)
                            </label>
                          </div>
                          <div className="radio">
                            <label className="mem-type">
                              <input
                                className="radio"
                                type="radio"
                                name="memberShipType"
                                id="annual"
                                value="A"
                                checked={formData.memberShipType === "A"}
                                onChange={handleInputChange}
                              />{" "}
                              Annual (Rs 1500.00)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.contribution &&
                          !isFieldValid("contribution")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Contribution
                      </label>
                      <div className="col-md-7">
                        <div
                          id="signupform-mem-type"
                          className="text-white mt-2"
                        >
                          <input
                            // placeholder="Enter Your Branch/Specialization"
                            name="contribution"
                            type="number"
                            value={formData.contribution}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            className={`form-control ${
                              formData.touched.contribution &&
                              !isFieldValid("contribution")
                                ? "red-border"
                                : "form-control custom-shadow"
                            }`}
                            autoComplete="off"
                          />
                          {formData.touched.contribution &&
                            !isFieldValid("contribution") && (
                              <div className="text-danger err_msg">
                                Contribution cannot be blank.
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="container mt-2">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6"></div>
                  <div className="col-md-6 d-flex justify-content-center gap-4">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Interested
                      </label>
                    </div>
                    <div className="col-md-6">
                      <div id="signupform-mem-type" className="text-white mt-2">
                        {interestedOptions.map((options, index) => (
                          <div className="checkbox" key={index}>
                            <input
                              className="checkbox"
                              type="checkbox"
                              id={options}
                              name="interestedTowards"
                              value={options}
                              checked={formData.interestedTowards.includes(
                                options
                              )}
                              onBlur={handleInputBlur}
                              onChange={handleInputChange}
                            />{" "}
                            {options}
                          </div>
                        ))}
                        {formData.touched.interestedTowards &&
                          !isFieldValid("interestedTowards") && (
                            <div className="text-danger err_msg">
                              Scheme Type cannot be blank
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                    {/* <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.contribution &&
                          !isFieldValid("contribution")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Contribution
                      </label>
                      <div className="col-md-7">
                        <div
                          id="signupform-mem-type"
                          className="text-white mt-2"
                        >
                          <input
                            // placeholder="Enter Your Branch/Specialization"
                            name="contribution"
                            type="number"
                            value={formData.contribution}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            className={`form-control ${
                              formData.touched.contribution &&
                              !isFieldValid("contribution")
                                ? "red-border"
                                : "form-control custom-shadow"
                            }`}
                          />
                          {formData.touched.contribution &&
                            !isFieldValid("contribution") && (
                              <div className="text-danger err_msg">
                                contribution cannot be blank.
                              </div>
                            )}
                        </div>
                      </div>
                    </div> */}
              {/* <div className="container">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Interested
                      </label>
                      <div className="col-md-6">
                        <div
                          id="signupform-mem-type"
                          className="text-white mt-2"
                        >
                          {interestedOptions.map((options, index) => (
                            <div className="checkbox" key={index}>
                              <input
                                className="checkbox"
                                type="checkbox"
                                id={options}
                                name="interestedTowards"
                                value={options}
                                checked={formData.interestedTowards.includes(
                                  options
                                )}
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                              />{" "}
                              {options}
                            </div>
                          ))}
                          {formData.touched.interestedTowards &&
                            !isFieldValid("interestedTowards") && (
                              <div className="text-danger err_msg">
                                Scheme Type cannot be blank
                              </div>
                            )}
                          <div
                            className="register-submit mt-4 mb-5"
                            style={{ marginLeft: "10.8vw" }}
                          >
                            <button
                              type="submit"
                              className="btn btn-block btn-success"
                              name="signup-button"
                            >
                              Signup
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="container mx-5 mt-4">
                        <div className="row mx-5">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-6">
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label for="inputField7" className="col-md-2 col-form-label"></label>
                                    <div className="col-md-6">
                                        <button
                                            type="submit"
                                            className="btn btn-success btn-block"
                                            name="signup-button"
                                        >
                                            Signup
                                        </button>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
              <div className="col-md-12 d-flex justify-content-center ">
                <div className="col-10 mt-4" style={{ marginLeft: "-30px" }}>
                  {/* <div className="text-white">
                    Registration form:<div>We need life membership 7500/-</div>
                  </div> */}
                  <div className="text-white">
                    Bank details:
                    <div>
                      Le Banyan de Pondicherry Engineering College State Bank Of
                      INDIA - A/C No:  36298538815  IFSCODE:  SBIN0001613, (RTGS
                      & NEFT transaction) MICR CODE: 605002004
                    </div>{" "}
                    Bank address: 
                    <div>
                      ADB – Agriculture Development Branch, Kamarajar Salai,
                      Brindavanam, Pondicherry 605 013
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-block btn-success"
                      name="signup-button"
                    >
                      Signup
                    </button>
                  </div>
                  {/* <div
                    className="register-submit mt-4 mb-5 d-flex justify-content-end align-items-end"
                    style={{ marginLeft: "10.8vw" }}
                  ></div> */}
                </div>
              </div>
            </form>
            <div className="b-title-description col-lg-12"></div>
            <p className="text-center mt-4 switch_signup">
              Already have an account?{" "}
              <Link
                to="/login"
                className="links-login"
                style={{ textDecoration: "none" }}
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Register;
