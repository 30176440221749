import React, { useEffect, useRef, useState } from "react";
import "./viewStyles/OTP.css";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import SubHeader from "./subheader";
import {
  banyanOtpVerificationApi,
  banyanResendOtpApi,
} from "../serviceApi/Auth-Services";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";
import swal from "sweetalert";

const OneTimePassword = () => {
  const [otp, setOTP] = useState(["", "", "", "", ""]); // Initialize with empty strings
  const otpBoxes = useRef([]);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState(false);
  const initialMinutes = 1; // Initial minutes
  const initialSeconds = 30; // Initial seconds

  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  const location = useLocation();

  const receivedId = location.state?.id;
  const receivedEmail = location.state?.email;

  const navto = location?.state?.navto;

  // console.log(receivedId, navto);

  useEffect(() => {
    let timerInterval;

    if (minutes === 0 && seconds === 0) {
      // Timer has reached 0:00, you can perform actions here
      setShowOTP(true);
      clearInterval(timerInterval);
    } else {
      timerInterval = setInterval(() => {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [minutes, seconds]);

  const handleInputChange = (e, index) => {
    const updatedOTP = [...otp];
    updatedOTP[index] = e.target.value;

    // Automatically focus on the next input field, if available
    if (index < updatedOTP?.length - 1 && e.target.value !== "") {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    if (index === "" && e > 0) {
      otpBoxes.current[e - 1].focus();
    }

    updatedOTP[index] = e.target.value.charAt(0);
    setOTP(updatedOTP);
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpBoxes.current[index - 1].focus();
    }
  };

  const submitOtpHandler = (event) => {
    event.preventDefault();
    const otpInputs = otp.join("");
    if (otpInputs === "") {
      swal("Oops", `OTP fields should not be empty`, "error", {
        button: "Try Again!",
      });
      return;
    }

    setIsLoading(true);
    const payload = {
      id: receivedId,
      otp: otpInputs,
    };

    banyanOtpVerificationApi(payload)
      .then((response) => {
        toast.dismiss();
        if (response.status === "success") {
          setIsLoading(false);
          if (navto === "/update-password") {
            navigate(navto, { state: { email: receivedEmail } });
          } else {
            localStorage.setItem("userDetails", response._id);
            navigate("/");
          }
          // navigate('/admin');
          setVerificationStatus("OTP Verified Successfully");
          toast.success(response.message);
        } else {
          setVerificationStatus("OTP Verification Failed");
          setIsLoading(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("A network error occurred");
        setIsLoading(false);
        setVerificationStatus("Error verifying OTP");
        console.log(error);
      });
  };

  const resendOptHandler = () => {
    const resentOptCode = { id: receivedId };
    setIsLoading(true);
    banyanResendOtpApi(resentOptCode)
      .then((response) => {
        toast.dismiss();
        if (response.status === "success") {
          setVerificationStatus("success");
          setIsLoading(false);
        } else {
          toast.error(response.message);
          setVerificationStatus("failed");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(error);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="One Time Password" />
      {isLoading && <SpinnerWrapper />}
      <div className="site-otp container">
        <h1 className="f-title-description b-title-description title-signup text-center">
          OTP Verfication
        </h1>
        <h6 className="text-center mt-5">
          Please enter the OTP number from your registered email.
        </h6>
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form
                className="otp-form"
                style={{ backgroundColor: "black" }}
                onSubmit={submitOtpHandler}
              >
                <h4 className="mt-2 text-white">Enter OTP Code</h4>
                <div className="otp-input-container">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="number"
                      className="otp-input mx-2 mt-2"
                      name={value}
                      value={value}
                      ref={(input) => (otpBoxes.current[index] = input)}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      maxLength="1"
                      inputMode="none"
                    />
                  ))}
                </div>
                {!showOTP && (
                  <p className="text-white">
                    Time Remaining: {minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                )}
                {showOTP && (
                  <>
                    <a
                      className="resend_otp opt_resend"
                      onClick={resendOptHandler}
                    >
                      Resend OTP?
                    </a>
                    <br></br>
                  </>
                )}
                {verificationStatus === "success" && (
                  <p className="otp_success success-label">
                    OTP sent successfully
                  </p>
                )}
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default OneTimePassword;
