import React, { useEffect, useRef, useState } from "react";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import Footer from "../components/Footer";
import "./viewStyles/contactUs.css";
import VerficationCode from "../assets/images/index.png";
import { banyanContactUsApi } from "../serviceApi/Auth-Services";
import swal from "sweetalert";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";

const Contactus = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTestimonial, setShowTestimonial] = useState(false);
  const firstInputRef = useRef();

  const facebookLink =
    "https://www.facebook.com/profile.php?id=100020183496579";
  const instagramLink =
    "https://www.instagram.com/lebanyandepec/?igsh=MTBxYm9qenFkN3RqOQ%3D%3D3D%3D";
  const linkedinLink = "https://www.linkedin.com/company/lebanyanpec/";

  const [contactUsForm, setContactUsForm] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
    verificationCode: "",
    touched: {
      name: false,
      email: false,
      subject: false,
      body: false,
      verificationCode: false,
    },
  });

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setContactUsForm({
      ...contactUsForm,
      [name]: value,
    });
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;

    setContactUsForm({
      ...contactUsForm,
      touched: {
        ...contactUsForm.touched,
        [name]: true,
      },
    });
  };

  const isFieldValid = (field) => {
    return contactUsForm[field] !== "";
    //Handle form validation here
  };

  const nameInput = contactUsForm?.touched?.name && !isFieldValid("name");
  const emailInput = contactUsForm?.touched?.email && !isFieldValid("email");
  const subjectInput =
    contactUsForm?.touched?.subject && !isFieldValid("subject");
  const BodyTextarea = contactUsForm?.touched?.body && !isFieldValid("body");
  const verficationCodeInput =
    contactUsForm?.touched.verificationCode &&
    !isFieldValid("verificationCode");
  const disableButton =
    (contactUsForm?.name &&
      contactUsForm?.email &&
      contactUsForm?.subject &&
      contactUsForm?.body) === "";

  const onContactUsHandler = (event) => {
    event.preventDefault();
    toast.dismiss();
    setIsLoading(true);
    const containsNumbers = /\d/.test(contactUsForm.name);
    const containsNameLength = contactUsForm.name.length >= 3;
    if (disableButton) {
      swal("Oops", `Please fill in the required fields`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (containsNameLength) {
      console.log("Name is valid");
    } else {
      swal("Oops", `Please enter a valid name`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (containsNumbers) {
      swal("Oops", `Numbers are not allowed in the name field`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      name: contactUsForm.name,
      email: contactUsForm.email,
      subject: contactUsForm.subject,
      body: contactUsForm.body,
      // verificationCode: contactUsForm.verificationCode,
    };
    banyanContactUsApi(payload)
      .then((response) => {
        if (response.status === "success") {
          setIsLoading(false);
          toast.success(`Email has been successfully sent`);
          setContactUsForm({
            name: "",
            email: "",
            subject: "",
            body: "",
            verificationCode: "",
            touched: {
              name: false,
              email: false,
              subject: false,
              body: false,
              verificationCode: false,
            },
          });
        } else {
          setIsLoading(false);
          setIsError(response.message);
          toast.error(response.message);
          return;
        }
      })
      .catch((err) => {
        setIsError(err.message);
        toast.error("A network error occurred");
        setIsLoading(false);
      });
  };

  const testimonialFormHandler = () => {};

  const testimonialHandler = () => {
    setShowTestimonial(!showTestimonial);
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Contact Us" />
      {isLoading && <SpinnerWrapper />}
      <div className="container b-container-top-50 gallery-container">
        <h3 className="text-center contact-us">We'd love to hear from you!</h3>
        <div className="row b-container-top-50">
          <div className="col-lg-6">
            <div className="f-title-description b-title-description">
              Contact us
            </div>
            <form
              id="contact-form"
              className="form-horizontal"
              onSubmit={onContactUsHandler}
            >
              <div className="form-group field-contactform-name required mb-3">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      for="inputField1"
                      className={`col-md-3 ${
                        contactUsForm.touched.name && !isFieldValid("name")
                          ? "col-form-label red-label"
                          : "col-form-label text-white"
                      }`}
                    >
                      Name
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className={`form-control ${
                        nameInput ? "red-border" : "custom-shadow"
                      }`}
                      name="name"
                      value={contactUsForm.name}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      ref={firstInputRef}
                      type="text"
                      autoComplete="off"
                    />
                    {nameInput && (
                      <div className="text-danger err_msg">
                        Name cannot be blank.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group field-contactform-email required mt-4">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      for="inputField1"
                      className={`col-md-3 ${
                        contactUsForm.touched.email && !isFieldValid("email")
                          ? "col-form-label red-label"
                          : "col-form-label text-white"
                      }`}
                    >
                      Email
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className={`form-control ${
                        emailInput ? "red-border" : "custom-shadow"
                      } `}
                      value={contactUsForm.email}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      name="email"
                      type="email"
                      autoComplete="off"
                    />
                    {emailInput && (
                      <div className="text-danger err_msg">
                        Email cannot be blank.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group field-contactform-name required mt-4">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      for="inputField1"
                      className={`col-md-3 ${
                        contactUsForm.touched.subject &&
                        !isFieldValid("subject")
                          ? "col-form-label red-label"
                          : "col-form-label text-white"
                      }`}
                    >
                      Subject
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className={`form-control ${
                        subjectInput
                          ? "red-border"
                          : "form-control custom-shadow"
                      }`}
                      value={contactUsForm.subject}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      name="subject"
                      type="text"
                      autoComplete="off"
                    />
                    {subjectInput && (
                      <div className="text-danger err_msg">
                        Subject cannot be blank.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group field-contactform-subject required mt-4">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      for="inputField1"
                      className={`col-md-3 ${
                        contactUsForm.touched.body && !isFieldValid("body")
                          ? "col-form-label red-label"
                          : "col-form-label text-white"
                      }`}
                    >
                      Body
                    </label>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      style={{
                        height: "100px",
                      }}
                      type="text"
                      value={contactUsForm.body}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      id="contactform-body"
                      className={`form-control ${
                        BodyTextarea
                          ? "red-border"
                          : "form-control custom-shadow"
                      }`}
                      rows="4"
                      cols="50"
                      name="body"
                      autoComplete="off"
                    />
                    {BodyTextarea && (
                      <div className="text-danger err_msg">
                        Body cannot be blank.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8 d-md-flex justify-content-end align-items-center p-1">
                    <button type="submit" className="btn btn-primary btn-block">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group mt-5 mb-5">
                <div className="col-lg-offset-9 col-lg-3 m-5"></div>
              </div>
            </form>
          </div>
          <div className="col-lg-6">
            <div className="f-title-description b-title-description">
              Contact Info
            </div>
            <div className="contact-info">
              <div className="pull-left">
                <icon className="fa fa-map-marker icon_bg"></icon>
              </div>
              <div className="caption mx-4">
                <h5 className="contact-section">Le Banyan de PEC</h5>
                <p className="contact_sec">
                  21, Kannayar St, Kuvayarpalayam , Pondicherry -605013 , INDIA.
                </p>
              </div>
            </div>
            <div className="contact-info">
              <div className="pull-left">
                <icon className="fa fa-phone icon_bg"></icon>
              </div>
              <div className="caption mx-4">
                <h5 className="contact-section">Phone Number</h5>
                <p className="contact_sec">+91 9443051212, +1 732 543 3630</p>
              </div>
            </div>
            <div className="contact-info">
              <div className="pull-left">
                <icon className="fa fa-envelope icon_bg"></icon>
              </div>
              <div className="caption mx-4">
                <h5 className="mail-section">Email</h5>
                <div className="col-12 d-flex justify-content-center">
                  <div className="caption">
                    <p className="contact_sec">
                      <a
                        className="mail_to"
                        onClick={() => {
                          window.open("mailto:lebanyandepec@pec.edu");
                        }}
                      >
                        lebanyandepec@pec.edu{" "}
                      </a>{" "}
                      {/* ,{" "} */}
                      <br />
                      <a
                        className="mail_to"
                        onClick={() => {
                          // window.open("mailto:lebanyandepec@gmail.com");
                          window.open(
                            "mailto:support_lebanyandepec@ptuniv.edu.in"
                          );
                        }}
                      >
                        support_lebanyandepec@ptuniv.edu.in
                      </a>
                    </p>
                  </div>
                  {/* <a
                    className="mail_to"
                    onClick={() => {
                      window.open("mailto: support@iinvsys.com");
                    }}
                  >
                    lebanyandepec@pec.edu{" "}
                  </a>
                  <p className="contact_sec" style={{ paddingRight: "0.5vw" }}>
                    ,
                  </p> */}
                  {/* <a
                    className="mail_to"
                    onClick={() => {
                      // window.open("mailto:lebanyandepec@gmail.com");
                      window.open("mailto:support_lebanyandepec@ptuniv.edu.in");
                    }}
                  >
                    support_lebanyandepec@ptuniv.edu.in
                  </a> */}
                </div>
              </div>
            </div>
            <div className="contact-info">
              <div className="pull-left mt-1">
                <icon className="fa fa-laptop icon_bg"></icon>
              </div>
              <div className="col-8 caption mx-4">
                <h5 className="contact-section">Social Media</h5>
                <div className="col-3 d-flex justify-content-between align-items-center">
                  <a
                    className="social-media-cont"
                    href={facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <icon className="fa fa-facebook icon_bg"></icon>
                  </a>
                  <a
                    className="social-media-cont"
                    href={instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <icon className="fa fa-instagram icon_bg"></icon>
                  </a>
                  <a
                    className="social-media-cont"
                    href={linkedinLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <icon className="fa fa-linkedin icon_bg"></icon>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial Review */}
          {/* <div className="col-lg-12">
            <div className="f-title-description b-title-description">
              Add a testimonial review
            </div>
            {!showTestimonial && (
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block-testimonies"
                  onClick={testimonialHandler}
                >
                  Add a review
                </button>
              </div>
            )}
            {showTestimonial && (
              <div className="col-md-6 mt-4">
                <form
                  id="contact-form"
                  className="form-horizontal"
                  onSubmit={onContactUsHandler}
                >
                  <div className="form-group field-loginform-email required mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="col-md-3">
                        <label
                          for="inputField1"
                          className="col-md-3 col-form-label text-white"
                        >
                          Name
                        </label>
                      </div>
                      <div className="col-md-9">
                        <input
                          className={`form-control ${
                            nameInput ? "red-border" : "custom-shadow"
                          }`}
                          name="name"
                          type="text"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group field-contactform-subject required mt-4">
                    <div className="d-flex justify-content-between">
                      <div className="col-md-3">
                        <label
                          for="inputField1"
                          className="col-md-3 col-form-label text-white"
                        >
                          Testimonial
                        </label>
                      </div>
                      <div className="col-md-9">
                        <textarea
                          style={{
                            height: "100px",
                          }}
                          type="text"
                          id="contactform-body"
                          className={`form-control ${
                            BodyTextarea
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          rows="4"
                          cols="50"
                          name="body"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container mt-3">
                    <div className="row justify-content-end">
                      <div className="col-md-8 d-md-flex justify-content-end align-items-center p-1 gap-3">
                        {showTestimonial && (
                          <button
                            type="submit"
                            className="btn btn-primary btn-block-testimonies"
                            onClick={testimonialHandler}
                          >
                            Cancel review
                          </button>
                        )}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block-testimonies"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-5 mb-5">
                    <div className="col-lg-offset-9 col-lg-3 m-5"></div>
                  </div>
                </form>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Contactus;
