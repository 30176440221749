// SpinnerWrapper.js
import React from "react";
import { SpinnerDiamond } from "spinners-react";
import "./viewStyles/spinner.css";

function SpinnerWrapper() {
  return (
    <div className="spinner-container">
      <SpinnerDiamond
        size={50}
        thickness={150}
        speed={100}
        color="rgba(172, 110, 57, 1)"
        secondaryColor="rgba(172, 110, 57, 1)"
      />
    </div>
  );
}

export default SpinnerWrapper;
