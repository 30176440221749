import React, { useEffect, useRef, useState } from "react";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import Footer from "../components/Footer";
import "./viewStyles/updatePassword.css";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { banyanUpdatePasswordApi } from "../serviceApi/Auth-Services";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";

const UpdatePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const firstInputRef = useRef();

  const navigate = useNavigate();

  const location = useLocation();

  const receivedEmail = location.state?.email;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      email: receivedEmail,
      password: password,
      confirmPassword: confirmPassword,
    };

    // Check if passwords match and meet criteria
    if (password === confirmPassword && password.length >= 6) {
      setIsPasswordValid(true);
      setIsFormSubmitted(true);

      // You can proceed with form submission or password update logic here
      banyanUpdatePasswordApi(payload)
        .then((response) => {
          toast.dismiss();
          if (response.status === "success") {
            setIsLoading(false);
            localStorage.clear();
            navigate("/login");
            toast.success("Password updated successfully");
          } else {
            setIsLoading(false);
            toast.error(response.message);
            return;
          }
        })
        .catch((err) => {
          toast.error("A network error occurred");
          setIsLoading(false);
        });
    } else {
      setIsPasswordValid(false);
      setIsLoading(false);
    }
  };

  const goToLogin = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Update Password" />
      {isLoading && <SpinnerWrapper />}
      <div className="container b-container-top-50 gallery-container mb-5">
        {/* <h3 className="text-center update-password-title">
                    {isFormSubmitted && "Password has been updated"}
                </h3> */}
        <div className="container upd-pass mt-5">
          <div className="row  justify-content-center">
            <div className="col-md-6 ">
              <div
                className="card shadow"
                style={{
                  backgroundColor: "black",
                  border: "1px solid #ddd",
                  borderRadius: "20px",
                }}
              >
                <div className="card-header text-white text-center">
                  <h3 className="mb-0 pass_head">Update Password</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mt-3">
                      <label
                        htmlFor="password"
                        className="form-label text-white"
                      >
                        Password
                      </label>
                      <div className="password-input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          // placeholder="Password"
                          value={password}
                          ref={firstInputRef}
                          className={`form-control custom-shadow ${
                            isPasswordValid ? "" : "red-border"
                          }`}
                          onChange={handlePasswordChange}
                        />
                        <i
                          style={{ color: "white" }}
                          className={`fa ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      </div>
                    </div>
                    <div className="mt-3">
                      <label
                        htmlFor="password"
                        className="form-label text-white"
                      >
                        Confirm Password
                      </label>
                      <div className="password-input-container">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          // placeholder="Password"
                          value={confirmPassword}
                          className={`form-control custom-shadow ${
                            isPasswordValid ? "" : "red-border"
                          }`}
                          onChange={handleConfirmPasswordChange}
                        />
                        <i
                          style={{ color: "white" }}
                          className={`fa ${
                            showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      </div>
                    </div>
                    {!isPasswordValid && (
                      <div className="text-danger">
                        Passwords must match and be at least 6 characters long.
                      </div>
                    )}
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default UpdatePassword;
