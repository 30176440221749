import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import "./viewStyles/forgotPassword.css";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { banyanForgotPasswordApi } from "../serviceApi/Auth-Services";
import Swal from "sweetalert2";
import { BsCheckCircleFill } from "react-icons/bs";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const firstInputRef = useRef();

  console.log(location);

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    // Check if the email is valid (you can use a regular expression or a library like "validator")
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setIsValidEmail(emailPattern.test(inputEmail));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      email,
    };
    // Check if the email is valid before submitting
    if (isValidEmail) {
      banyanForgotPasswordApi(payload)
        .then((response) => {
          toast.dismiss();
          if (response.status === "success") {
            setIsLoading(false);
            navigate("/otp-pin", {
              state: {
                id: response.id,
                email: payload.email,
                navto: "/update-password",
              },
            });
            localStorage.setItem("userEmail", payload.email);
            setVerificationStatus("Email Verified Successfully");
            setIsSubmitted(true);
            toast.success(response.message);
          } else {
            setVerificationStatus("Email Verification Failed");
            setIsLoading(false);
            toast.error(response.message);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          setVerificationStatus("Error verifying email");
          setIsLoading(false);
          toast.error("A network error occurred");
        });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Request password reset" />
      {isLoading && <SpinnerWrapper />}
      <div className="container b-container-top-50 gallery-container mb-5">
        <h3 className="text-center forgot-password-title">
          Please fill out your email. A link to reset password will be sent
          there.
        </h3>
        <div className="container res-pass mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6 ">
              <div className="card shadow">
                <div
                  className="card-header text-white text-center"
                  style={{ background: "#000" }}
                >
                  <h3 className="mb-0">Forgot Password</h3>
                </div>
                <div className="card-body" style={{ background: "#000" }}>
                  {isSubmitted ? (
                    <h6 className="text-center text-success">
                      Loading to update password page...
                    </h6>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          className="form-label"
                          style={{ color: "#fff" }}
                        >
                          Email address
                        </label>
                        <input
                          onChange={handleEmailChange}
                          value={email}
                          type="email"
                          ref={firstInputRef}
                          className={`form-control custom-shadow ${
                            isValidEmail ? "" : "is-invalid"
                          }`}
                          id="email"
                          autoComplete="off"
                        />
                      </div>
                      {!isValidEmail && (
                        <div className="text-danger">Invalid email address</div>
                      )}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* <footer className="footer fixed-bottom text-center">
                <div className="container">
                    <p className="pull-left">All Rights Reserved <span className="color-green">&copy; Le Banyan De PEC</span> 2023</p>
                </div>
            </footer> */}
    </React.Fragment>
  );
};

export default ForgotPassword;
