import React from "react";
import "./viewStyles/testimonies.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import testimonialComma from "../assets/images/testimonies_comma.png";

function Testimonial() {
  return (
    <React.Fragment>
      <div className="container-fluid d-flex justify-content-center mt-4">
        <div className="col-12 p-4">
          <h2 className="testimonial_heading_text d-flex justify-content-center">
            Testimonials
          </h2>
          <h6 className="events-headers home_events d-flex justify-content-center">
            Hear what our beneficiaries has to say about Le Banyan de PEC Add
            their department details
          </h6>
          <Swiper
            direction="horizontal"
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            style={{ width: "95%" }}
          >
            <div className="testimonial-slider">
              <SwiperSlide
                className="container-fluid d-flex justify-content-center"
                id="slider_container"
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_one">
                        <div className="d-flex justify-content-start flex-column align-items-start gap-2 mt-3">
                          <div>
                            <div className="events-headers home_events text-left">
                              Mohanraj.G
                            </div>
                            <div>19CH1031,</div>
                            <div>Department of Chemical Engineering</div>
                          </div>
                          <div className="col-md-12 customer_review_info text-left">
                            I am writing to convey my deepest appreciation for
                            the outstanding scholarship provided by Le Banyan De
                            Pec during the academic year 2022-2023. This
                            scholarship significantly reduced the financial
                            burden of my college education and allowed me to
                            commit more time in my academic studies. I am deeply
                            thankful for this opportunity, and I am committed to
                            making the most of it. Thank you again for your
                            encouragement and faith in my ability to succeed.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_one">
                        <div className="d-flex justify-content-center flex-column align-items-start gap-2 mt-3">
                          <div>
                            <div className="events-headers home_events text-center">
                              Pawan Kumar
                            </div>
                            <div>19CS1085</div>
                            <div>CSE</div>
                          </div>
                          <div className="col-md-12 customer_review_info text-left">
                            I am writing to express my heartfelt gratitude for
                            the generous scholarship I have received from Le
                            Banyan De Pec. I had received the scholarship amount
                            of Rs18851 on 4 Jan 2023. This scholarship will
                            significantly alleviate the financial burden of my
                            education and allow me to focus more on my academic
                            pursuits. I am sincerely grateful for this
                            incredible opportunity and committed to making the
                            most of it. Thank you once again for your support
                            and belief in my abilities.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_two">
                        <div className="d-flex justify-content-center flex-column align-items-start gap-2 mt-3">
                          <div className="">
                            <div className="events-headers home_events text-center">
                              Gayathri R
                            </div>
                            <div>19IT1005</div>
                            <div>B.Tech(IT)</div>
                          </div>
                          <div className="col-md-12 col-sm-12 customer_review_info text-left">
                            Greetings of the day, I hope this email finds you
                            well. I am writing to express my sincere gratitude
                            for the credit of Rs.6000/- towards my exam fees
                            through Le Banyan de PEC scholarship. I am truly
                            honored and immensely grateful to be a recipient of
                            this generous scholarship. I would also like to
                            extend my thanks to all individuals involved in
                            making this scholarship program possible. Wishing
                            you continued success in your mission to uplift
                            students and make a positive impact on their lives.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_one">
                        <div className="d-flex justify-content-center flex-column align-items-center gap-2 mt-3">
                          <div className="events-headers home_events text-center">
                            K Pandeeswari
                          </div>
                          <div className="col-md-12 col-sm-12 customer_review_info text-center">
                            I'm Pandeeswari.K who studied B.Tech (Information
                            Technology) in the period 2019-2023. I received
                            Rs.6000 as Scholarship from Le Banyan de PEC
                            Association for paying my Exam fees.I received this
                            scholarship because I didn't have any academic
                            backlogs. This scholarship helped me to pay my fees
                            at the right time and these kinds of scholarships
                            motivate students to maintain consistent grades and
                            help students with financial issues to focus on
                            their academics. I'm always grateful to LE BANYAN DE
                            PEC association for providing me with this
                            scholarship. In the future, I also wish to join this
                            association to provide financial support for
                            students who are consistent in their academics and
                            financial issues.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="container-fluid d-flex justify-content-center"
                id="slider_container"
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_two">
                        <div className="d-flex justify-content-center flex-column align-items-start gap-3 mt-3">
                          <div>
                            <div className="events-headers home_events text-center">
                              A Prudhvi Raj
                            </div>
                            <div>19IT1005</div>
                            <div>B.Tech(IT)</div>
                          </div>
                          <div className="col-md-12 col-sm-12 customer_review_info text-left">
                            I am ARADADI PRUDHVI RAJ from (19IT1005) IT dept. I
                            received a scholarship from LE BANYAN DE PEC of Rs.
                            6000 the 6th and 7th semesters. This scholarship
                            helped me a lot in paying the semester examination
                            fee. And I am very thankful to LE BANYAN DE PEC.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_three">
                        <div className="d-flex justify-content-center flex-column align-items-start gap-2 mt-3">
                          <div>
                            <div className="events-headers home_events text-center">
                              Hariprasad. Z
                            </div>
                            <div>2019-2023</div>
                            <div>CSE</div>
                          </div>
                          <div className="col-md-12 col-sm-12 customer_review_info text-left">
                            I, Hariprasad one of the "Merit Scholarship
                            recipients of Le Banyan de PEC", would like to take
                            this opportunity to express my heartfelt gratitude
                            to the Le Banyan contributors. I am committed to
                            utilizing the knowledge and skills acquired during
                            my College days to make a positive impact in my
                            field and society as a whole. Once again, thank you
                            for believing me and investing in my future. I got
                            placed via campus recruitment in one of the top MNC.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="customer_review d-flex justify-content-center align-items-center text-white">
                      <div className="col-md-2 review_logo">
                        <img src={testimonialComma} className="img-fluid" />
                      </div>
                      <div className="col-md-11 d-flex justify-content-start flex-column align-items-center testimonial_contents_three">
                        <div className="d-flex justify-content-center flex-column align-items-start mt-3">
                          <div className="events-headers home_events text-center">
                            Anes J
                          </div>
                          <div style={{ height: "40px" }}></div>
                          <div className="col-md-12 col-sm-12 customer_review_info text-left">
                            Dear Le Banyan de PEC, I am writing to express my
                            heartfelt gratitude for the scholarship awarded to
                            me. Your generosity has made a significant impact on
                            my academic journey, and I am truly grateful for the
                            support. Currently, I am working as a Solution
                            Engineer at Vunet Systems, leveraging my skills and
                            knowledge acquired during my B.Tech studies, where I
                            graduated with a CGPA of 7.7. This scholarship not
                            only eases the financial burden but also motivates
                            me to strive for excellence in my career and
                            academic pursuits. Thank you once again for the
                            opportunity.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
      {/* <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Mohanraj.G</h5>
                <h5 className="card-title">
                  19CH1031
                  <br />
                  Department of Chemical Engineering
                </h5>
                <p className="card-text">
                  <small className="text-muted">
                    I am writing to convey my deepest appreciation for the
                    outstanding scholarship provided by Le Banyan De Pec during
                    the academic year 2022-2023. This scholarship significantly
                    reduced the financial burden of my college education and
                    allowed me to commit more time in my academic studies. I am
                    deeply thankful for this opportunity, and I am committed to
                    making the most of it. Thank you again for your
                    encouragement and faith in my ability to succeed.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Pawan Kumar</h5>
                <h5 className="card-title">
                  19CS1085 <br />
                  CSE
                </h5>
                <p className="card-text">
                  <small className="text-muted">
                    {" "}
                    I am writing to express my heartfelt gratitude for the
                    generous scholarship I have received from Le Banyan De Pec.
                    I had received the scholarship amount of Rs18851 on 4 Jan
                    2023. This scholarship will significantly alleviate the
                    financial burden of my education and allow me to focus more
                    on my academic pursuits. I am sincerely grateful for this
                    incredible opportunity and committed to making the most of
                    it. Thank you once again for your support and belief in my
                    abilities.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Gayathri R</h5>
                <h5 className="card-title">
                  19IT1005 <br />
                  B.Tech(IT)
                </h5>
                <p className="card-text">
                  <small className="text-muted">
                    {" "}
                    Greetings of the day, I hope this email finds you well. I am
                    writing to express my sincere gratitude for the credit of
                    Rs.6000/- towards my exam fees through Le Banyan de PEC
                    scholarship. I am truly honored and immensely grateful to be
                    a recipient of this generous scholarship. I would also like
                    to extend my thanks to all individuals involved in making
                    this scholarship program possible. Wishing you continued
                    success in your mission to uplift students and make a
                    positive impact on their lives.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">A Prudhvi Raj</h5>
                <h5 className="card-title">
                  19IT1005 <br />
                  B.Tech(IT)
                </h5>
                <p className="card-text">
                  <small className="text-muted">
                    I am ARADADI PRUDHVI RAJ from (19IT1005) IT dept. I received
                    a scholarship from LE BANYAN DE PEC of Rs. 6000 the 6th and
                    7th semesters. This scholarship helped me a lot in paying
                    the semester examination fee. And I am very thankful to LE
                    BANYAN DE PEC.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Hariprasad. Z</h5>
                <h5 className="card-title">
                  2019-2023 <br />
                  CSE
                </h5>
                <p className="card-text">
                  <small className="text-muted">
                    {" "}
                    I, Hariprasad one of the "Merit Scholarship recipients of Le
                    Banyan de PEC", would like to take this opportunity to
                    express my heartfelt gratitude to the Le Banyan
                    contributors. I am committed to utilizing the knowledge and
                    skills acquired during my College days to make a positive
                    impact in my field and society as a whole. Once again, thank
                    you for believing me and investing in my future. I got
                    placed via campus recruitment in one of the top MNC.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Anes J</h5>
                <p className="card-text" style={{ height: "40px" }}></p>
                <p className="card-text">
                  <small className="text-muted">
                    {" "}
                    Dear Le Banyan de PEC, I am writing to express my heartfelt
                    gratitude for the scholarship awarded to me. Your generosity
                    has made a significant impact on my academic journey, and I
                    am truly grateful for the support. Currently, I am working
                    as a Solution Engineer at Vunet Systems, leveraging my
                    skills and knowledge acquired during my B.Tech studies,
                    where I graduated with a CGPA of 7.7. This scholarship not
                    only eases the financial burden but also motivates me to
                    strive for excellence in my career and academic pursuits.
                    Thank you once again for the opportunity.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
}

export default Testimonial;
