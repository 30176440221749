import React, { useEffect, useRef } from "react";
import "./viewStyles/popUp.css"; // Create a CSS file for styling the popup

const PopupModal = ({ onClose }) => {
  const modalRef = useRef(null);

  // Close modal if clicked outside of it
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  return (
    <div className="popup">
      <div className="popup-inner" ref={modalRef}>
        <div
          className="modal-content bg-dark text-white"
          style={{ textDecoration: "none" }}
        >
          <div className="modal-header" style={{ borderBottom: "none" }}>
            <h5 className="modal-title" id="exampleModalLabel">
              Welcome to Le Banyan de Pec
            </h5>
          </div>
          <div className="modal-body mt-2">
            We are 80G certified. If you need 80G, please send the below details
            to our treasurer, Prof. Jayabharathi @ 9443292660 via Whatsapp,
            jayabharathy@ptuniv.edu.in via email.
          </div>
          <ul className="mt-2">
            <li>Name</li>
            <li>Father's name</li>
            <li>Address</li>
            <li>PAN</li>
            <li>Amount</li>
            <li>Transaction number</li>
            <li>Date of transaction</li>
            <li>Email address</li>
          </ul>
          <div className="modal-footer" style={{ borderTop: "none" }}>
            <button className="btn btn-block btn-success" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
