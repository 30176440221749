import presidentVP from "../assets/execBoard/KalpanaM.jpg";
import gilberImg from "../assets/execBoard/Gilber.jpg";
import balaImg from "../assets/execBoard/Bala.jpg";
import jayakumarImg from "../assets/execBoard/Jayekumar.jpg";
import padmanadanImg from "../assets/execBoard/Padmanaban.jpg";
import rajeshImg from "../assets/execBoard/RajeshNair.jpg";
import sivaSirImg from "../assets/execBoard/SivakumarG.jpg";
import vengatImg from "../assets/execBoard/VengatPeroumal.jpg";
import jayabharathyImg from "../assets/execBoard/Jayabharthy.jpg";
import kavithaImg from "../assets/execBoard/Kavitha.jpg";
import regiImg from "../assets/execBoard/RegiMattews.jpg";
import sriVidhya from "../assets/execBoard/Srividya.jpg";
import srinivasan from "../assets/execBoard/Srinivasan.jpg";
import sarulatha from "../assets/execBoard/Sarulatha.jpg";
import santhanaLakshmi from "../assets/execBoard/Sandanalakshmi.jpg";
import kalpana from "../assets/execBoard/Kalpana.jpg";

export const Data_Members = [
  {
    id: "P1",
    title: "President",
    name: "MRS. R. KALPANA",
    role: "PRESIDENT – INTERIM",
    college: "Pondicherry Engineering College",
  },
  {
    id: "AV1",
    title: "Advisor",
    imgSrc: srinivasan,
    name: "DR. E.SRINIVASAN",
    role: "VICE PRESIDENT",
    college: "Pondicherry Engineering College",
  },
  {
    id: "VP1",
    title: "Vice President",
    imgSrc: vengatImg,
    name: "MR. DJ. VENGAT PEROUMAL",
    role: "ADMINISTRATION",
    college: "Pondicherry Engineering College",
  },
  {
    id: "VP2",
    title: "Vice President",
    imgSrc: presidentVP,
    name: " MRS. KALPANA M",
    role: "PROJECTS",
    college: "Pondicherry Engineering College",
  },
  {
    id: "GS1",
    title: "Co-General Secretary",
    name: " DR. Ka. SELVARADJOU",
    role: "CO-GENERAL SECRETARY",
    college: "Pondicherry Engineering College",
  },
  {
    id: "GS2",
    title: "Co-General Secretary",
    imgSrc: sivaSirImg,
    name: "MR. G. SIVAKUMAR",
    role: "CO-GENERAL SECRETARY",
    college: "Pondicherry Engineering College",
  },
  // {
  //   id: "SS1",
  //   title: "Structure of the Society",
  //   name: "DR. T.G.PALANIVELU",
  //   role: "PRESIDENT",
  //   description: "Former Principal",
  //   college: "Pondicherry Engineering College",
  // },
  // {
  //   id: "SS2",
  //   title: "Structure of the Society",
  //   name: "DR. D. GOVINDARAJULU",
  //   role: "VICE PRESIDENT",
  //   description: "Former Principal",
  //   college: "Pondicherry Engineering College",
  // },
  // {
  //   id: "SS3",
  //   title: "Structure of the Society",
  //   name: "DR. E.SRINIVASAN",
  //   role: "ADVISOR",
  //   description: "Professor, ECE",
  //   college: "Pondicherry Engineering College",
  // },

  // {
  //   id: "AD1",
  //   title: "Adminstration",
  //   name: "Mr. DJ. VENGAT PEROUMAL",
  //   role: "VICE PRESIDENT - ADMINISTRATION",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },
  // {
  //   id: "AD2",
  //   title: "Adminstration",
  //   name: "DR. (MRS). R.KALPANA",
  //   role: "CO-GENERAL SECRETARY",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },
  // {
  //   id: "AD3",
  //   title: "Adminstration",
  //   name: "MRS. M. KALPANA",
  //   role: "CO-GENERAL SECRETARY",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },

  {
    id: "ED1",
    title: "Education",
    name: "DR. K. SARULATHA",
    imgSrc: sarulatha,
    role: "JOINT SECRETARY",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "ED2",
    title: "Education",
    imgSrc: rajeshImg,
    name: "MR. RAJESH NAIR",
    role: "AN EXECUTIVE MEMBER EDUCATION",
    description: null,
    college: "Pondicherry Engineering College",
  },

  {
    id: "WL1",
    title: "Welfare",
    imgSrc: jayakumarImg,
    name: "DR. JAYEKUMAR",
    role: "JOINT SECRETARY",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "WL2",
    title: "Welfare",
    name: "DR. PADMANABAN",
    imgSrc: padmanadanImg,
    role: "AN EXECUTIVE MEMBER WELFARE",
    description: null,
    college: "Pondicherry Engineering College",
  },

  {
    id: "WE1",
    title: "Women Empowerment",
    name: "DR. R. KALPANA",
    imgSrc:kalpana,
    role: "JOINT SECRETARY",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "WE2",
    title: "Women Empowerment",
    name: "PROF. (MRS). NALINI",
    role: "ADVISOR",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "WE3",
    title: "Women Empowerment",
    name: " DR. KAVITHA",
    imgSrc: kavithaImg,
    role: "AN EXECUTIVE MEMBER WOMEN EMPOWERMENT",
    description: null,
    college: "Pondicherry Engineering College",
  },

  {
    id: "EN1",
    title: "Environment",
    name: "DR. R. SANTHANALAKSHMI",
    imgSrc:santhanaLakshmi,
    role: "JOINT SECRETARY",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "EN2",
    title: "Environment",
    name: "MR. JAYAKUMAR",
    role: "EXECUTIVE MEMBER",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "EN3",
    title: "Environment",
    name: "MRS. PALLAVI",
    role: "EXECUTIVE MEMBER",
    description: null,
    college: "Pondicherry Engineering College",
  },

  {
    id: "NG1",
    title: "Nature & Organic Gardening",
    name: "DR. (MRS.) JAYA BHARATHI",
    role: "EXECUTIVE MEMBER",
    description: null,
    college: "Pondicherry Engineering College",
  },

  {
    id: "TR1",
    title: "Treasurer",
    name: "DR. (MRS.) JAYABHARATHY",
    imgSrc: jayabharathyImg,
    role: "TREASURER",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "TR2",
    title: "Treasurer",
    name: "Mrs. SRIVIDYA ARUMUGAM",
    imgSrc: sriVidhya,
    role: "JOINT TREASURER",
    description: null,
    college: "Pondicherry Engineering College",
  },

  // {
  //   id: "MEM1",
  //   title: "Membership",
  //   name: "Mr. RAJESH NAIR",
  //   role: "EXECUTIVE MEMBER",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },

  {
    id: "FD1",
    title: "Fundraiser",
    name: "Dr. DEEPAK SELVANATHAN",
    role: "EXECUTIVE MEMBER",
    description: null,
    college: "Pondicherry Engineering College",
  },

  // {
  //   id: "WC1",
  //   title: "Web Coordinator",
  //   name: "Dr. KA. SELVARADJOU",
  //   role: "WEB COORDINATOR",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },
  // {
  //   id: "WC2",
  //   title: "Web Coordinator",
  //   name: "Mr. K. KISHORE ANTHUVAN",
  //   role: "EXECUTIVE MEMBER",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },
  // {
  //   id: "WC3",
  //   title: "Web Coordinator",
  //   name: "Mr. VIJAY",
  //   role: "EXECUTIVE MEMBER",
  //   description: null,
  //   college: "Pondicherry Engineering College",
  // },
  {
    id: "WC4",
    title: "Web Coordinator",
    imgSrc: balaImg,
    name: "MR.Bala Paralan",
    role: "WEB SPECIALIST",
    description: null,
    college: "IInvsys Private Limited",
  },
  {
    id: "EM1",
    title: "Executive Member",
    name: "Dr. DEEPAK SELVANATHAN",
    description: null,
    college: "Pondicherry Engineering College",
  },
  {
    id: "EM2",
    title: "Executive Member",
    name: "Mr. G. REGI MATTEWS",
    imgSrc: regiImg,
    description: null,
    role: "EXECUTIVE MEMBER",
    college: "Pondicherry Engineering College",
  },
  {
    id: "EM3",
    title: "Executive Member",
    name: "Mr. G.BALAJI",
    description: null,
    role: "EXECUTIVE MEMBER",
    college: "Pondicherry Engineering College",
  },

  {
    id: "MR1",
    title: "Media or Public Relation",
    name: "DR. V. GOVINDASWAMY",
    role: "MEDIA / PUBLIC RELATION",
    description: null,
    role: "EXECUTIVE MEMBER",
    college: "Pondicherry Engineering College",
  },
  {
    id: "MR2",
    title: "Media/Public Relation",
    imgSrc: gilberImg,
    name: "Gilber A",
    role: "MEDIA / PUBLIC RELATION",
    description: null,
    college: "Pondicherry Engineering College",
  },
];

export const sideTab = [
  {
    id: "0",
    text: "President",
    members: [
      {
        id: "P1",
        title: "President",
        name: "Mrs. R. KALPANA",
        role: "PRESIDENT – INTERIM",
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "1",
    text: "Advisor",
    members: [
      {
        id: "AV1",
        title: "Advisor",
        imgSrc: srinivasan,
        name: "Dr. E.SRINIVASAN",
        role: "ADVISOR",
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "2",
    text: "Vice President",
    members: [
      {
        id: "VP1",
        title: "Vice President",
        name: "Mr. DJ. VENGAT PEROUMAL",
        imgSrc: vengatImg,
        role: "ADMINISTRATION",
        college: "Pondicherry Engineering College",
      },
      {
        id: "VP2",
        title: "Vice President",
        imgSrc: presidentVP,
        name: " Mrs. KALPANA M",
        role: "PROJECTS",
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "3",
    text: "Co-General Secretary",
    members: [
      {
        id: "GS1",
        title: "Co-General Secretary",
        name: " Dr. Ka. SELVARADJOU",
        role: "CO-GENERAL SECRETARY",
        college: "Pondicherry Engineering College",
      },
      {
        id: "GS2",
        title: "Co-General Secretary",
        imgSrc: sivaSirImg,
        name: "Mr. G. SIVAKUMAR",
        role: "CO-GENERAL SECRETARY",
        college: "Pondicherry Engineering College",
      },
    ],
  },
  // {
  //   id: "4",
  //   text: "Structure of the Society",
  //   members: [
  //     {
  //       id: "SS1",
  //       title: "Structure of the Society",
  //       name: "DR. T.G.PALANIVELU",
  //       role: "PRESIDENT",
  //       description: "Former Principal",
  //       college: "Pondicherry Engineering College",
  //     },
  //     {
  //       id: "SS2",
  //       title: "Structure of the Society",
  //       name: "DR. D. GOVINDARAJULU",
  //       role: "VICE PRESIDENT",
  //       description: "Former Principal",
  //       college: "Pondicherry Engineering College",
  //     },
  //     {
  //       id: "SS3",
  //       title: "Structure of the Society",
  //       name: "DR. E.SRINIVASAN",
  //       role: "ADVISOR",
  //       description: "Professor, ECE",
  //       college: "Pondicherry Engineering College",
  //     },
  //   ],
  // },
  // {
  //   id: "5",
  //   text: "Adminstration",
  //   members: [
  //     {
  //       id: "AD1",
  //       title: "Adminstration",
  //       name: "Mr. DJ. VENGAT PEROUMAL",
  //       role: "VICE PRESIDENT - ADMINISTRATION",
  //       description: null,
  //       college: "Pondicherry Engineering College",
  //     },
  //     {
  //       id: "AD2",
  //       title: "Adminstration",
  //       name: "DR. (Mrs). R.KALPANA",
  //       role: "CO-GENERAL SECRETARY",
  //       description: null,
  //       college: "Pondicherry Engineering College",
  //     },
  //     {
  //       id: "AD3",
  //       title: "Adminstration",
  //       name: "MRS. M. KALPANA",
  //       role: "CO-GENERAL SECRETARY",
  //       description: null,
  //       college: "Pondicherry Engineering College",
  //     },
  //   ],
  // },
  {
    id: "6",
    text: "Education",
    members: [
      {
        id: "ED1",
        title: "Education",
        name: "DR. K. SARULATHA",
        imgSrc: sarulatha,
        role: "JOINT SECRETARY",
        description: null,
        college: "Pondicherry Engineering College",
      },
      {
        id: "ED2",
        title: "Education",
        name: "MR. RAJESH NAIR",
        imgSrc: rajeshImg,
        role: "AN EXECUTIVE MEMBER EDUCATION",
        description: null,
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "7",
    text: "Welfare",
    members: [
      {
        id: "WL1",
        title: "Welfare",
        name: "Dr. JAYEKUMAR",
        imgSrc: jayakumarImg,
        role: "JOINT SECRETARY",
        description: null,
        college: "Pondicherry Engineering College",
      },
      {
        id: "WL2",
        title: "Welfare",
        name: "Dr. PADMANABAN",
        imgSrc: padmanadanImg,
        role: "AN EXECUTIVE MEMBER WELFARE",
        description: null,
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "8",
    text: "Woman Empowerment",
    members: [
      {
        id: "WE1",
        title: "Women Empowerment",
        name: "Dr. R. KALPANA",
        imgSrc:kalpana,
        role: "JOINT SECRETARY",
        description: null,
        college: "Pondicherry Engineering College",
      },
      {
        id: "WE2",
        title: "Women Empowerment",
        name: "PROF. (MRS). NALINI",
        role: "ADVISOR",
        description: null,
        college: "Pondicherry Engineering College",
      },
      {
        id: "WE3",
        title: "Women Empowerment",
        name: " Dr. KAVITHA",
        imgSrc: kavithaImg,
        role: "AN EXECUTIVE MEMBER WOMEN EMPOWERMENT",
        description: null,
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "9",
    text: "Environment",
    members: [
      {
        id: "EN1",
        title: "Environment",
        name: "Dr. R. SANTHANALAKSHMI",
        imgSrc:santhanaLakshmi,
        role: "JOINT SECRETARY",
        description: null,
        college: "Pondicherry Engineering College",
      },
      // {
      //   id: "EN2",
      //   title: "Environment",
      //   name: "MR. JAYAKUMAR",
      //   role: "EXECUTIVE MEMBER",
      //   description: null,
      //   college: "Pondicherry Engineering College",
      // },
      // {
      //   id: "EN3",
      //   title: "Environment",
      //   name: "MRS. PALLAVI",
      //   role: "EXECUTIVE MEMBER",
      //   description: null,
      //   college: "Pondicherry Engineering College",
      // },
    ],
  },
  // {
  //   id: "10",
  //   text: "Nature & Organic Gardening",
  //   members: [
  //     {
  //       id: "NG1",
  //       title: "Nature & Organic Gardening",
  //       name: "DR. (MRS.) JAYA BHARATHI",
  //       role: "EXECUTIVE MEMBER",
  //       description: null,
  //       college: "Pondicherry Engineering College",
  //     },
  //   ],
  // },
  {
    id: "11",
    text: "Treasurer",
    members: [
      {
        id: "TR1",
        title: "Treasurer",
        name: "Dr. JAYABHARATHY",
        imgSrc: jayabharathyImg,
        role: "TREASURER",
        description: null,
        college: "Pondicherry Engineering College",
      },
      {
        id: "TR2",
        title: "Treasurer",
        name: "Mrs. SRIVIDYA ARUMUGAM",
        imgSrc: sriVidhya,
        role: "JOINT TREASURER",
        description: null,
        college: "Pondicherry Engineering College",
      },
    ],
  },
  // {
  //   id: "12",
  //   text: "Membership",
  //   members: [
  //     {
  //       id: "MEM1",
  //       title: "Membership",
  //       name: "MR. RAJESH NAIR",
  //       role: "EXECUTIVE MEMBER",
  //       description: null,
  //       college: "Pondicherry Engineering College",
  //     },
  //   ],
  // },
  // {
  //   id: "13",
  //   text: "Fundraiser",
  //   members: [
  //     {
  //       id: "FD1",
  //       title: "Fundraiser",
  //       name: "DR. DEEPAK SELVANATHAN",
  //       role: "EXECUTIVE MEMBER",
  //       description: null,
  //       college: "Pondicherry Engineering College",
  //     },
  //   ],
  // },
  {
    id: "14",
    text: "Web Coordinator",
    members: [
      // {
      //   id: "WC1",
      //   title: "Web Coordinator",
      //   name: "DR. KA. SELVARADJOU",
      //   role: "WEB COORDINATOR",
      //   description: null,
      //   college: "Pondicherry Engineering College",
      // },
      // {
      //   id: "WC2",
      //   title: "Web Coordinator",
      //   name: "MR. K. KISHORE ANTHUVAN",
      //   role: "EXECUTIVE MEMBER",
      //   description: null,
      //   college: "Pondicherry Engineering College",
      // },
      // {
      //   id: "WC3",
      //   title: "Web Coordinator",
      //   name: "MR. VIJAY",
      //   role: "EXECUTIVE MEMBER",
      //   description: null,
      //   college: "Pondicherry Engineering College",
      // },
      {
        id: "WC4",
        title: "Web Coordinator",
        imgSrc: balaImg,
        name: "MR.Bala Paralan",
        role: "WEB SPECIALIST",
        description: null,
        college: "IInvsys Private Limited",
      },
    ],
  },
  {
    id: "15",
    text: "Executive Member",
    members: [
      {
        id: "EM1",
        title: "Executive Member",
        name: "Dr. DEEPAK SELVANATHAN",
        description: null,
        role: "EXECUTIVE MEMBER",
        college: "Pondicherry Engineering College",
      },
      {
        id: "EM2",
        title: "Executive Member",
        name: "Mr. G. REGI MATTEWS",
        imgSrc: regiImg,
        description: null,
        role: "EXECUTIVE MEMBER",
        college: "Pondicherry Engineering College",
      },
      {
        id: "EM3",
        title: "Executive Member",
        name: "Mr. G.BALAJI",
        description: null,
        role: "EXECUTIVE MEMBER",
        college: "Pondicherry Engineering College",
      },
    ],
  },
  {
    id: "16",
    text: "Media or public relation",
    members: [
      {
        id: "MR1",
        title: "Media/Public Relation",
        name: "DR. V. GOVINDASWAMY",
        role: "MEDIA / PUBLIC RELATION",
        description: null,
        college: "Pondicherry Engineering College",
      },
      {
        id: "MR2",
        title: "Media/Public Relation",
        imgSrc: gilberImg,
        name: "Gilber A",
        role: "MEDIA / PUBLIC RELATION",
        description: null,
        college: "Pondicherry Engineering College",
      },
    ],
  },
];
